// @ts-nocheck
import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

export function Modal({ isOpen, onClose, title, children }: ModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className={cn(
        "fixed inset-0 bg-charcoal-950/80 backdrop-blur-sm",
        "z-[9999]",
        "animate-in fade-in duration-200"
      )}
    >
      <div className="min-h-full flex items-center justify-center p-4">
        <div className={cn(
          "w-full max-w-md",
          "bg-charcoal-800 rounded-xl",
          "shadow-2xl shadow-charcoal-950/50",
          "border border-charcoal-700/50",
          "animate-in slide-in-from-bottom-4 duration-300"
        )}>
          <div className="flex items-center justify-between p-6 border-b border-charcoal-700/50">
            <h2 className="text-lg font-medium text-white">{title}</h2>
            <button
              onClick={onClose}
              className={cn(
                "p-2 text-charcoal-400 hover:text-white",
                "rounded-lg hover:bg-charcoal-700/50",
                "transition-colors"
              )}
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          <div className="p-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}