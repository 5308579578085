// @ts-nocheck
import React from 'react';
import { LoginForm } from './LoginForm';
import { BetaLock } from './BetaLock';
import { SignUpForm } from './SignUpForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useState } from 'react';
import { cn } from '@/lib/utils';

export const AuthPage = () => {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [currentForm, setCurrentForm] = useState<'login' | 'signup' | 'reset'>('login');
  const [isAnimating, setIsAnimating] = useState(false);
  const [nextForm, setNextForm] = useState<'login' | 'signup' | 'reset'>('login');

  const handleFormTransition = (newForm: typeof currentForm) => {
    if (!isAnimating) {
      setIsAnimating(true);
      setNextForm(newForm);
      const container = document.querySelector('.flip-container');
      container?.classList.add('flipped');
      
      setTimeout(() => {
        setCurrentForm(newForm);
        setIsAnimating(false);
      }, 600);
    }
  };

  const handleBackToLogin = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setNextForm('login');
      const container = document.querySelector('.flip-container');
      container?.classList.remove('flipped');
      
      setTimeout(() => {
        setCurrentForm('login');
        setIsAnimating(false);
      }, 600);
    }
  };

  return (
    <div className="min-h-screen bg-charcoal-950 relative overflow-hidden">
      {/* Animated background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-charcoal-950 via-blue-900/10 to-purple-900/10 animate-gradient-xy" />
      
      {/* Animated gradient background */}
      <div className="absolute inset-0 bg-gradient-to-r from-electric-blue/5 via-purple-500/5 to-electric-blue/5 animate-pulse" />
      
      {/* Glowing orbs */}
      <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-electric-blue/10 rounded-full filter blur-[100px] animate-pulse-glow" />
      
      <div className={cn(
        "absolute inset-0 transition-all duration-700 ease-in-out transform",
        !isUnlocked ? "translate-y-0 opacity-100" : "-translate-y-24 opacity-0 pointer-events-none"
      )}>
        <BetaLock onUnlock={() => setIsUnlocked(true)} />
      </div>
      
      <div className={cn(
        "absolute inset-0 transition-all duration-700 ease-in-out transform z-10",
        isUnlocked ? "translate-y-0 opacity-100" : "translate-y-24 opacity-0 pointer-events-none"
      )}>
        <div className="min-h-screen flex items-center justify-center p-4">
          <div className="w-full max-w-md">
            <div className="group relative">
              {/* Glowing border effect */}
              <div className="absolute -inset-0.5 bg-gradient-to-r from-electric-blue/50 via-purple-500/50 to-electric-blue/50 rounded-2xl blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-gradient-xy" />
              
              <div className="relative bg-charcoal-800/90 rounded-xl border border-charcoal-700/50 shadow-xl shadow-charcoal-950/50 backdrop-blur-xl flex flex-col">
                <div className="flip-container">
                  <div className="card front p-8 justify-center">
                    <LoginForm
                      onSwitchToSignUp={() => handleFormTransition('signup')}
                      onSwitchToReset={() => handleFormTransition('reset')}
                    />
                  </div>
                  <div className="card back p-8 justify-center">
                    {nextForm === 'signup' ? (
                      <SignUpForm
                        onSwitchToLogin={handleBackToLogin}
                      />
                    ) : (
                      <ResetPasswordForm
                        onSwitchToLogin={handleBackToLogin}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};