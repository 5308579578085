// @ts-nocheck
import React from 'react';
import { useAuthStore } from '@/lib/store';
import { cn } from '@/lib/utils';

interface UserAvatarProps {
  email?: string;
  className?: string;
}

export const UserAvatar = ({ className }: UserAvatarProps) => {
  const user = useAuthStore((state) => state.user);
  const profile = useAuthStore((state) => state.profile);
  
  if (!user?.email) return null;

  // Use first letter of first name if available, otherwise email
  const initial = profile?.firstName 
    ? profile.firstName[0].toUpperCase()
    : user.email[0].toUpperCase();
  
  return (
    <div 
      className={cn(
        "relative flex-shrink-0 w-10 h-10 rounded-full",
        "bg-gradient-to-r from-electric-blue/20 to-blue-500/20",
        "flex items-center justify-center",
        "ring-2 ring-electric-blue/20 hover:ring-electric-blue/40",
        "transition-all duration-200",
        className
      )}
    >
      <span className="text-electric-blue font-semibold text-lg">
        {initial}
      </span>
    </div>
  );
};