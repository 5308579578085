// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useCanvasStore } from '@/lib/stores/canvasStore';
import { cn } from '@/lib/utils';
import { PlusCircle, MessageSquare, LayoutGrid, BookOpen } from 'lucide-react';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { format } from 'date-fns';
import { CanvasTypeSelector } from './CanvasTypeSelector';

const CANVAS_TYPE_ICONS = {
  'learning-program': {
    icon: BookOpen,
    label: 'Learning Program',
    color: 'text-purple-400'
  },
  'content-map': {
    icon: LayoutGrid,
    label: 'Content Map',
    color: 'text-blue-400'
  }
} as const;

export function ChatSidebar() {
  const { chats, activeChat, createChat, deleteChat, setActiveChat, updateChat, currentCanvasType, setCurrentCanvasType } = useChatListStore();
  const { type } = useCanvasStore();
  const [isDeleting, setIsDeleting] = useState<string | null>(null);
  const [isSliding, setIsSliding] = useState(false);

  // Filter out archived chats and sort by updatedAt
  const sortedChats = [...chats]
    .filter(chat => !chat.archived)
    .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());

  const handleChatClick = (chatId: string) => {
    setIsSliding(true);
    setActiveChat(chatId);
    setSidebarVisible(false);
    setTimeout(() => setIsSliding(false), 300); // Match transition duration
  };

  const handleCreateChat = () => {
    const chatId = createChat();
    setActiveChat(chatId);
    setSidebarVisible(false);
  };

  const handleDeleteChat = async (chatId: string) => {
    setIsDeleting(chatId);
    await new Promise(resolve => setTimeout(resolve, 300)); // Animation delay
    deleteChat(chatId);
    setIsDeleting(null);
  };

  const handleCanvasTypeChange = (type: 'learning-program' | 'content-map') => {
    setCurrentCanvasType(type);
  };

  return (
    <div className="w-64 h-full bg-charcoal-900 border-r border-charcoal-800 flex flex-col">
      <div className="p-4 space-y-3 border-b border-charcoal-800">
        <div className="flex flex-col gap-1">
          <span className="text-[10px] uppercase tracking-wider text-charcoal-400 font-medium px-1">Canvas</span>
          <CanvasTypeSelector 
            value={currentCanvasType} 
            onChange={handleCanvasTypeChange}
          />
        </div>

        <button
          onClick={handleCreateChat}
          className={cn(
            "w-full flex items-center justify-center gap-2 px-4 py-2 rounded-lg",
            "bg-gradient-to-r from-purple-800 via-blue-700 to-purple-800",
            "bg-[length:200%_100%]",
            "animate-shimmer",
            "text-white font-medium",
            "border border-purple-900/20",
            "transition-all duration-200",
            "hover:shadow-lg hover:shadow-purple-900/10"
          )}
        >
          <PlusCircle className="w-5 h-5" />
          New Chat
        </button>
      </div>

      <div className="flex-1 overflow-y-auto p-2 space-y-2">
        {sortedChats.map((chat) => {
          const canvasInfo = CANVAS_TYPE_ICONS[chat.canvasType as keyof typeof CANVAS_TYPE_ICONS];
          return (
            <div
              key={chat.id}
              className={cn(
                "group relative px-3 py-2.5 rounded-lg cursor-pointer",
                "hover:bg-charcoal-800/50",
                "transition-all duration-300 ease-out",
                activeChat === chat.id && "bg-charcoal-800",
                isDeleting === chat.id && "opacity-0 -translate-x-full",
                isSliding && "transform transition-transform duration-300"
              )}
              onClick={() => handleChatClick(chat.id)}
            >
              <div className="flex items-center gap-3">
                <div className="relative flex-shrink-0">
                  {canvasInfo ? (
                    <canvasInfo.icon className={cn("w-5 h-5", canvasInfo.color)} />
                  ) : (
                    <MessageSquare className="w-5 h-5 text-electric-blue" />
                  )}
                  {chat.requirements?.length > 0 && (
                    <div className={cn(
                      "absolute -top-1 -right-1",
                      "min-w-[16px] h-4 px-1",
                      "flex items-center justify-center",
                      "bg-electric-blue rounded-full",
                      "text-[10px] font-medium text-charcoal-900"
                    )}>
                      {chat.requirements.length}
                    </div>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <h3 className="text-sm font-medium text-white truncate">
                    {chat.title}
                  </h3>
                  <p className="text-xs text-charcoal-400">
                    {format(chat.updatedAt, 'MMM d, yyyy')}
                  </p>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteChat(chat.id);
                  }}
                  className={cn(
                    "absolute right-2 top-1/2 -translate-y-1/2",
                    "p-1.5 rounded-full",
                    "bg-charcoal-800/90 opacity-0 group-hover:opacity-100",
                    "text-charcoal-400 hover:text-red-400",
                    "transition-all duration-200"
                  )}
                >
                  <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}