// @ts-nocheck
import { getDocument } from 'pdfjs-dist';
import { ProcessedFile, FileProcessor } from '../types';
import { generateId } from '@/lib/utils';
import { Timestamp } from 'firebase/firestore';

export const processPdfFile: FileProcessor = async (file: File): Promise<ProcessedFile> => {
  try {
    const buffer = await file.arrayBuffer();
    const pdf = await getDocument(new Uint8Array(buffer)).promise;
    const numPages = pdf.numPages;
    let fullText = '';
    let wordCount = 0;

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageText = content.items
        .map((item: any) => item.str)
        .join(' ')
        .replace(/\s+/g, ' ');
      
      fullText += pageText + '\n\n';
      wordCount += pageText.split(/\s+/).length;
    }

    return {
      id: generateId(),
      name: file.name,
      type: 'pdf',
      content: fullText.trim(),
      metadata: {
        size: file.size,
        lastModified: file.lastModified,
        pageCount: numPages,
        wordCount,
      },
      createdAt: Timestamp.now(),
    };
  } catch (error) {
    console.error('Error processing PDF:', error);
    throw new Error('Failed to process PDF file');
  }
};