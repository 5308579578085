// @ts-nocheck
import React, { useEffect } from 'react';
import { Canvas as LearningPathCanvas } from '@/components/canvas/learningPath/Canvas';
import { Canvas as ContentMapCanvas } from '@/components/canvas/contentMap/Canvas';
import { useCanvasStore } from '@/lib/stores/canvasStore';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { cn } from '@/lib/utils';
import { Info } from 'lucide-react';

export function Canvas() {
  const { activeChat, chats, updateChat, getCanvasType } = useChatListStore();
  const chat = chats.find(c => c.id === activeChat);
  const canvasType = getCanvasType(activeChat);

  useEffect(() => {
    if (activeChat) {
      if (chat) {
        if (chat.status === 'initializing' && chat.messages.length > 0) {
          updateChat(activeChat, { status: 'active' });
        }
        // Ensure canvasType is set
        if (!chat.canvasType) {
          updateChat(activeChat, { canvasType: 'learning-program' });
        }
      }
    }
  }, [activeChat, chat, updateChat]);

  if (!activeChat) {
    return (
      <div className="h-full bg-charcoal-900/50 flex items-center justify-center">
        <div className="flex flex-col items-center gap-4 text-center p-6">
          <Info className="w-8 h-8 text-charcoal-400" />
          <p className="text-charcoal-400">
            Select a chat or create a new one to start using the canvas
          </p>
        </div>
      </div>
    );
  }

  const renderCanvas = () => {
    switch (canvasType) {
      case 'learning-program':
        return <LearningPathCanvas />;
      case 'content-map':
        return <ContentMapCanvas />;
      default:
        return <LearningPathCanvas />;
    }
  };

  return (
    <div className={cn(
      "h-full bg-charcoal-900/50",
      "transition-opacity duration-300 opacity-100"
    )}>
      {renderCanvas()}
    </div>
  );
}