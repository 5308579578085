// @ts-nocheck
import React, { useState, useCallback } from 'react';
import { useChatListStore } from '../stores/chatListStore';
import { processFile } from '../services/fileProcessing';
import { extractRequirements } from '../utils/requirements';

export function useChat() {
  const [inputValue, setInputValue] = useState('');
  const { activeChat, updateChat } = useChatListStore();
  
  const sendMessage = useCallback(async (content: string, files?: File[]) => {
    if (!activeChat) return;
    
    try {
      // Process any uploaded files
      if (files?.length) {
        for (const file of files) {
          const processedFile = await processFile(file);
          // Extract requirements from file content
          const requirements = await extractRequirements(processedFile.content);
          // Add requirements to chat
          requirements.forEach(req => {
            updateChat(activeChat, chat => ({
              ...chat,
              requirements: [...chat.requirements, req]
            }));
          });
        }
      }
      
      // Add user message
      updateChat(activeChat, chat => ({
        ...chat,
        messages: [...chat.messages, {
          id: Date.now().toString(),
          content,
          role: 'user',
          timestamp: new Date(),
          files: files?.map(f => ({
            name: f.name,
            type: f.type,
            size: f.size
          }))
        }]
      }));
      
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }, [activeChat, updateChat]);

  return {
    inputValue,
    setInputValue,
    sendMessage
  };
}