// @ts-nocheck
import React, { useState, useCallback, useRef } from 'react';
import { TableRow } from './TableRow';
import type { ViewMode } from './types';
import { cn } from '@/lib/utils';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useSearchStateStore } from '@/lib/stores/searchStateStore'; 
import { useEffect } from 'react';

interface ContentTableProps {
  viewMode: ViewMode;
  selectedRows: Set<number>;
  isInitializing?: boolean;
  onSelectRows: (rows: Set<number>) => void;
}

export function ContentTable({ viewMode, selectedRows, isInitializing, onSelectRows }: ContentTableProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const [rows, setRows] = useState(() => Array.from({ length: 50 }, (_, i) => i));
  const { clearAllSearches } = useSearchStateStore();
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartIndex, setDragStartIndex] = useState<number | null>(null);
  const [dragEndIndex, setDragEndIndex] = useState<number | null>(null);

  const addMoreRows = () => {
    const newRows = Array.from({ length: rows.length + 50 }, (_, i) => i);
    setRows(newRows); 
    // Scroll to show new rows were added
    setTimeout(() => {
      parentRef.current?.scrollTo({
        top: parentRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }, 100);
  };

  // Clear searches when unmounting
  useEffect(() => {
    return () => clearAllSearches();
  }, [clearAllSearches]);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => viewMode === 'pretty' ? 240 : 64, [viewMode]),
    overscan: 5,
    getItemKey: useCallback((index: number) => index, [])
  });

  const handleDragStart = useCallback((index: number) => {
    setIsDragging(true);
    setDragStartIndex(index);
    setDragEndIndex(index);
  }, []);

  const handleDragMove = useCallback((index: number) => {
    if (isDragging && dragStartIndex !== null) {
      setDragEndIndex(index);
      
      // Calculate range of rows to select
      const start = Math.min(dragStartIndex, index);
      const end = Math.max(dragStartIndex, index);
      const selectedIndices = new Set(
        Array.from({ length: end - start + 1 }, (_, i) => start + i)
      );
      
      onSelectRows(selectedIndices);
    }
  }, [isDragging, dragStartIndex, onSelectRows]);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
    setDragStartIndex(null);
    setDragEndIndex(null);
  }, []);

  const handleSelectRow = useCallback((index: number, selected: boolean) => {
    onSelectRows(new Set(
      selected 
        ? [...selectedRows, index]
        : [...selectedRows].filter(i => i !== index)
    ));
  }, [selectedRows, onSelectRows]);

  const handleSelectAll = useCallback((selected: boolean) => {
    onSelectRows(new Set(selected ? rows : []));
  }, [rows, onSelectRows]);

  return (
    <div 
      ref={parentRef} 
      className="h-full overflow-y-auto"
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
    >
      <div className="px-4 focus:outline-none">
        <div
          className="relative w-full"
          style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
        >
          <div
            className="absolute top-3 left-0 w-full space-y-2"
            style={{
              transform: `translateY(${rowVirtualizer.getVirtualItems()[0]?.start ?? 0}px)`
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => (
              <TableRow
                key={virtualRow.key}
                index={virtualRow.index}
                viewMode={viewMode}
                selected={selectedRows.has(virtualRow.index)}
                isActive={activeRowIndex === virtualRow.index}
                isDragging={isDragging && dragStartIndex === virtualRow.index}
                isInitializing={isInitializing}
                onSelect={(selected) => handleSelectRow(virtualRow.index, selected)}
                onDragStart={() => handleDragStart(virtualRow.index)}
                onDragMove={() => handleDragMove(virtualRow.index)}
              />
            ))}
            
            {/* Add Rows Button as alternate row */}
            <div className="py-20">
              <button
                onClick={addMoreRows}
                disabled={isInitializing}
                className={cn(
                  "w-full px-6 py-3 rounded-lg",
                  "bg-charcoal-800/30 hover:bg-charcoal-800/50",
                  "text-sm text-charcoal-400 hover:text-white",
                  "border border-charcoal-700/30 hover:border-electric-blue/30",
                  "transition-all duration-200",
                  isInitializing && "opacity-50 cursor-not-allowed"
                )}
              >
                Add 50 more rows
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}