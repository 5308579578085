// @ts-nocheck
import * as XLSX from 'xlsx';
import { ProcessedFile, FileProcessor } from '../types';
import { generateId } from '@/lib/utils';
import { Timestamp } from 'firebase/firestore';

export const processSpreadsheetFile: FileProcessor = async (file: File): Promise<ProcessedFile> => {
  try {
    const buffer = await file.arrayBuffer();
    const workbook = XLSX.read(new Uint8Array(buffer), { type: 'array' });
    const result: Record<string, any[]> = {};
    let totalCells = 0;

    workbook.SheetNames.forEach(sheetName => {
      if (!workbook.Sheets[sheetName]['!ref']) return;
      
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: false,
        defval: ''
      });
      
      result[sheetName] = data;
      totalCells += data.reduce((acc, row) => acc + (Array.isArray(row) ? row.length : 1), 0);
    });

    // Convert to formatted text
    const content = Object.entries(result)
      .map(([sheetName, data]) => {
        const rows = data.map(row => 
          Array.isArray(row) ? row.join('\t') : String(row)
        );
        return `Sheet: ${sheetName}\n${rows.join('\n')}`;
      })
      .join('\n\n');

    return {
      id: generateId(),
      name: file.name,
      type: file.name.endsWith('.xlsx') ? 'xlsx' : 'csv',
      content,
      metadata: {
        size: file.size,
        lastModified: file.lastModified,
        pageCount: workbook.SheetNames.length,
        wordCount: totalCells,
      },
      createdAt: Timestamp.now(),
    };
  } catch (error) {
    console.error('Error processing spreadsheet:', error);
    throw new Error('Failed to process spreadsheet');
  }
};