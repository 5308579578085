// @ts-nocheck
import { create } from 'zustand';
import type { SearchResult } from '@/components/canvas/contentMap/types';

interface SearchState {
  term: string;
  results: SearchResult[];
  isLoading: boolean;
  error: string | null;
}

interface SearchStateStore {
  searches: Map<number, SearchState>;
  setSearch: (index: number, data: Partial<SearchState>) => void;
  clearSearch: (index: number) => void;
  clearAllSearches: () => void;
}

export const useSearchStateStore = create<SearchStateStore>((set) => ({
  searches: new Map(),
  
  setSearch: (index, data) => set((state) => {
    const newSearches = new Map(state.searches);
    const currentSearch = newSearches.get(index) || {
      term: '',
      results: [],
      isLoading: false,
      error: null
    };
    
    newSearches.set(index, {
      ...currentSearch,
      ...data
    });
    
    return { searches: newSearches };
  }),
  
  clearSearch: (index) => set((state) => {
    const newSearches = new Map(state.searches);
    newSearches.delete(index);
    return { searches: newSearches };
  }),
  
  clearAllSearches: () => set({ searches: new Map() })
}));