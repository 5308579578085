// @ts-nocheck
import { create } from 'zustand';

interface SettingsState {
  isOpen: boolean;
  activeTab: 'profile' | 'subscription' | 'notifications' | 'security' | 'search' | 'integrations' | 'ai' | 'api' | 'archived';
  showUdemyModal: boolean;
  setShowSettings: (isOpen: boolean, tab?: SettingsState['activeTab'], showUdemyModal?: boolean) => void;
}

export const useSettingsStore = create<SettingsState>((set) => ({
  isOpen: false,
  activeTab: 'profile',
  showUdemyModal: false,
  setShowSettings: (isOpen, tab = 'profile', showUdemyModal = false) => 
    set({ isOpen, activeTab: tab, showUdemyModal })
}));