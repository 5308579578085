// @ts-nocheck
import React from 'react';
import { Star, Clock, Users, Tag } from 'lucide-react';
import { cn } from '@/lib/utils';
import type { SearchResult } from './types';

interface CourseCardProps {
  course: SearchResult;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
  compact?: boolean;
  minimal?: boolean;
}

export function CourseCard({ course, onClick, selected, className, compact, minimal }: CourseCardProps) {
  const {
    title,
    headline,
    rating,
    num_reviews,
    content_info,
    image_240x135,
    instructional_level_simple,
    visible_instructors = [],
    url
  } = course;

  const courseUrl = `https://www.udemy.com${url}`;

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          window.open(courseUrl, '_blank', 'noopener,noreferrer');
          onClick?.();
        }
      }}
      className={cn(
        "flex gap-4 rounded-lg transition-all duration-200",
        !minimal && "p-4",
        "bg-charcoal-800/30 hover:bg-charcoal-800/40",
        "border border-charcoal-700/30 hover:border-electric-blue/30",
        selected && "bg-electric-blue/5 hover:bg-electric-blue/10",
        "cursor-pointer",
        compact && "p-2",
        className
      )}
    >
      {/* Course Thumbnail */}
      {image_240x135 && !minimal && (
        <div className="flex-shrink-0">
          <img
            src={image_240x135}
            alt={title}
            className={cn(
              "object-cover rounded-lg",
              compact ? "w-24 h-16" : "w-40 h-24"
            )}
            loading="lazy"
          />
        </div>
      )}

      {/* Course Info */}
      <div className="flex-1 min-w-0">
        {/* Title and Level */}
        <div className="flex items-start justify-between gap-2 mb-1">
          <h3 className={cn(
            "font-medium text-white line-clamp-2",
            compact ? "text-sm" : "text-base",
            minimal && "line-clamp-1"
          )}>
            {title}
          </h3>
          {instructional_level_simple && (
            <span className="flex-shrink-0 px-2 py-0.5 text-xs rounded-full whitespace-nowrap bg-electric-blue/10 text-electric-blue border border-electric-blue/20">
              {instructional_level_simple}
            </span>
          )}
        </div>

        {!minimal && (
          <>
            <p 
              className={cn(
                "text-sm text-charcoal-300 mb-2",
                compact ? "line-clamp-1" : "line-clamp-2"
              )}
              dangerouslySetInnerHTML={{ __html: headline }}
            />

            {/* Stats Row */}
            <div className="flex items-center gap-4 text-sm">
              {rating && (
                <div className="flex items-center gap-1 text-amber-400">
                  <Star className="w-4 h-4" />
                  <span>{rating.toFixed(2)}</span>
                  {num_reviews && (
                    <span className="text-charcoal-400">
                      ({num_reviews.toLocaleString()})
                    </span>
                  )}
                </div>
              )}

              {content_info && (
                <div className="flex items-center gap-1 text-charcoal-400">
                  <Clock className="w-4 h-4" />
                  <span>
                    {typeof course.hrs_of_content_f === 'number' 
                      ? `${course.hrs_of_content_f.toFixed(1)}h`
                      : content_info}
                  </span>
                </div>
              )}
            </div>

            {/* Instructors */}
            {visible_instructors.length > 0 && (
              <div className={cn(
                "flex items-center gap-2 mt-2",
                "text-xs text-charcoal-400"
              )}>
                <Users className="w-3 h-3" />
                <span className="flex gap-2 overflow-hidden">
                  {visible_instructors.map((instructor, i) => (
                    <button
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(`https://www.udemy.com${instructor.url}`, '_blank', 'noopener,noreferrer');
                      }}
                      className={cn(
                        "instructor-link hover:text-electric-blue transition-colors truncate",
                        "hover:underline"
                      )}
                    >
                      {instructor.display_name}
                      {i < visible_instructors.length - 1 && ","}
                    </button>
                  ))}
                </span>
              </div>
            )}
          </>
        )}

        {/* Minimal view stats */}
        {minimal && (
          <div className="flex items-center gap-4 text-xs text-charcoal-400">
            {rating && (
              <div className="flex items-center gap-1">
                <Star className="w-3 h-3 text-amber-400" />
                <span>{rating.toFixed(2)} ({course.num_reviews?.toLocaleString()})</span>
              </div>
            )}
            {course.hrs_of_content_f && (
              <div className="flex items-center gap-1">
                <Clock className="w-3 h-3" />
                <span>
                  {typeof course.hrs_of_content_f === 'number'
                    ? `${course.hrs_of_content_f.toFixed(2)}h`
                    : content_info}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}