// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Lock, Mail, Sparkles } from 'lucide-react';
import { cn } from '@/lib/utils';

const MAX_ATTEMPTS = 5;
const LOCKOUT_DURATION = 24 * 60 * 60 * 1000; // 24 hours

interface BetaLockProps {
  onUnlock: () => void;
}

export const BetaLock: React.FC<BetaLockProps> = ({ onUnlock }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [isLockedOut, setIsLockedOut] = useState(false);
  const [lockoutEndTime, setLockoutEndTime] = useState<number | null>(null);

  // Check for existing beta access
  useEffect(() => {
    const hasAccess = localStorage.getItem('betaAccess') === 'true';
    if (hasAccess) {
      onUnlock();
    }
  }, [onUnlock]);

  useEffect(() => {
    // Load state from localStorage
    const storedAttempts = localStorage.getItem('betaLockAttempts');
    const storedLockoutEnd = localStorage.getItem('betaLockoutEnd');
    
    if (storedAttempts) {
      setAttempts(parseInt(storedAttempts));
    }
    
    if (storedLockoutEnd) {
      const endTime = parseInt(storedLockoutEnd);
      if (endTime > Date.now()) {
        setIsLockedOut(true);
        setLockoutEndTime(endTime);
      } else {
        // Clear expired lockout
        localStorage.removeItem('betaLockoutEnd');
        localStorage.removeItem('betaLockAttempts');
      }
    }
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (isLockedOut) return;
    
    const newAttempts = attempts + 1;
    setAttempts(newAttempts);
    localStorage.setItem('betaLockAttempts', newAttempts.toString());
    
    if (code.toLowerCase() === 'lampados') {
      setError(false);
      localStorage.removeItem('betaLockAttempts');
      localStorage.removeItem('betaLockoutEnd');
      localStorage.setItem('betaAccess', 'true');
      onUnlock();
    } else {
      setError(true);
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
      
      if (newAttempts >= MAX_ATTEMPTS) {
        const lockoutEnd = Date.now() + LOCKOUT_DURATION;
        setIsLockedOut(true);
        setLockoutEndTime(lockoutEnd);
        localStorage.setItem('betaLockoutEnd', lockoutEnd.toString());
      }
    }
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:support@philomathia.ai?subject=Access%20Request%20for%20Phil';
  };

  const getRemainingTime = () => {
    if (!lockoutEndTime) return '';
    const remaining = Math.max(0, lockoutEndTime - Date.now());
    const hours = Math.floor(remaining / (60 * 60 * 1000));
    const minutes = Math.floor((remaining % (60 * 60 * 1000)) / (60 * 1000));
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-charcoal-950/90 backdrop-blur-sm">
      <div className={cn(
        "bg-charcoal-800/90 rounded-xl p-8 border border-charcoal-700/50",
        "shadow-xl max-w-md w-full mx-4",
        "animate-in fade-in slide-in-from-bottom-4 duration-300",
        isShaking && "animate-shake"
      )}>
        <div className="flex flex-col items-center text-center space-y-6">
          {/* Logo/Icon */}
          <div className={cn(
            "w-16 h-16 rounded-full",
            "bg-gradient-to-br from-electric-blue/20 to-purple-500/20",
            "border border-electric-blue/30",
            "flex items-center justify-center",
            "animate-pulse"
          )}>
            <Lock className="w-8 h-8 text-electric-blue" />
          </div>

          {/* Title */}
          <div className="space-y-2">
            <h2 className={cn(
              "text-2xl font-bold",
              "bg-gradient-to-r from-white via-electric-blue to-white",
              "bg-[length:200%_100%] bg-clip-text text-transparent",
              "animate-shimmer"
            )}>
              Phil Beta Access
            </h2>
            <p className="text-charcoal-300">
              Enter your access code to continue
            </p>
          </div>

          {/* Code Input Form */}
          <form onSubmit={handleSubmit} className="w-full space-y-4">
            <div className="relative">
              <input
                type="text"
                disabled={isLockedOut}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                  setError(false);
                }}
                className={cn(
                  "w-full px-4 py-3 bg-charcoal-700/50",
                  "border-2 rounded-lg",
                  "text-white text-center text-lg tracking-wider",
                  "placeholder-charcoal-400",
                  "focus:outline-none focus:ring-2 focus:ring-electric-blue/50",
                  "transition-all duration-200",
                  error ? "border-red-500/50" : "border-charcoal-600/50"
                )}
                placeholder="Enter Code"
              />
              <Sparkles className={cn(
                "absolute right-3 top-1/2 -translate-y-1/2",
                "w-5 h-5 text-electric-blue",
                "transition-opacity duration-200",
                code ? "opacity-100" : "opacity-0"
              )} />
            </div>

            <button
              type="submit"
              disabled={isLockedOut}
              className={cn(
                "w-full py-3 rounded-lg font-medium",
                isLockedOut 
                  ? "bg-red-500/10 border-red-500/30 text-red-400 cursor-not-allowed"
                  : "bg-electric-blue/10 hover:bg-electric-blue/20 border-electric-blue/30 hover:border-electric-blue/50 text-electric-blue",
                "transition-all duration-200",
                "group relative overflow-hidden"
              )}
            >
              <span className="relative z-10">
                {isLockedOut ? 'Access Locked' : 'Unlock Access'}
              </span>
            </button>
          </form>

          {/* Contact Link */}
          <button
            onClick={handleEmailClick}
            className={cn(
              "flex items-center gap-2",
              "text-sm text-charcoal-400 hover:text-electric-blue",
              "transition-colors duration-200"
            )}
          >
            <Mail className="w-4 h-4" />
            <span>Request Access</span>
          </button>

          {error && (
            <p className="text-sm text-red-400 text-center">
              {isLockedOut ? (
                <>
                  Too many failed attempts. Please try again in {getRemainingTime()}
                  <br />
                  <span className="text-charcoal-400">
                    Contact support for immediate access
                  </span>
                </>
              ) : (
                <>
                  Invalid access code. {MAX_ATTEMPTS - attempts} attempts remaining.
                  <br />
                  Please try again or request access.
                </>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};