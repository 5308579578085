// @ts-nocheck
import mammoth from 'mammoth';
import { ProcessedFile, FileProcessor } from '../types';
import { generateId } from '@/lib/utils';
import { Timestamp } from 'firebase/firestore';

export const processWordFile: FileProcessor = async (file: File): Promise<ProcessedFile> => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    const text = result.value;
    const wordCount = text.split(/\s+/).length;

    return {
      id: generateId(),
      name: file.name,
      type: file.name.endsWith('.docx') ? 'docx' : 'doc',
      content: text,
      metadata: {
        size: file.size,
        lastModified: file.lastModified,
        wordCount,
      },
      createdAt: Timestamp.now(),
    };
  } catch (error) {
    console.error('Error processing Word document:', error);
    throw new Error('Failed to process Word document');
  }
};