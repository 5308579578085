// @ts-nocheck
import React, { useState } from 'react';
import { Mail, Lock, Loader2, AlertCircle } from 'lucide-react';
import { signInWithEmailAndPassword } from '@/lib/auth';
import { isValidEmail } from '@/lib/utils';
import { AuthButtons } from './AuthButtons';
import { cn } from '@/lib/utils';

interface LoginFormProps {
  onSwitchToSignUp: () => void;
  onSwitchToReset: () => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSwitchToSignUp, onSwitchToReset }) => {
  const [email, setEmail] = useState(() => {
    const savedEmail = localStorage.getItem('lastEmail') || '';
    return savedEmail;
  });
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(() => {
    const savedEmail = localStorage.getItem('lastEmail') || '';
    return isValidEmail(savedEmail);
  });
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(isValidEmail(newEmail));
    setPassword(''); // Clear password when email changes
    setIsPasswordValid(false);
    setError('');
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(newPassword.length >= 6);
    setError('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    
    if (!email.trim()) {
      setError('Please enter your email address');
      return;
    }
    
    // Store email for potential password reset
    localStorage.setItem('lastEmail', email);

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    if (!password) {
      setError('Please enter your password');
      return;
    }
    
    if (password.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }

    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(email, password);
      setPassword(''); // Clear password on success
    } catch (err: any) {
      // Generic error message for invalid credentials
      if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
        setError('Invalid email or password');
      } else if (err.code === 'auth/account-exists-with-different-credential') {
        setError('An account already exists with this email. Please sign in with your original provider to link accounts');
      } else {
        setError(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-form-content">
      <h2 className="text-3xl font-bold text-white mb-6 text-center">
        Sign In
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-charcoal-400 h-5 w-5" />
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              autoFocus
              placeholder="Email"
              className="w-full pl-10 pr-4 py-2 bg-charcoal-700 border border-charcoal-600 rounded-lg focus:ring-2 focus:ring-electric-blue focus:border-transparent text-white placeholder-charcoal-400"
            />
          </div>

          <div className={cn("relative transition-all duration-300", !isEmailValid && "opacity-50 pointer-events-none")}>
            <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-charcoal-400 h-5 w-5" />
            <input
              disabled={!isEmailValid}
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              className={cn(
                "w-full pl-10 pr-4 py-2 bg-charcoal-700 border border-charcoal-600 rounded-lg",
                "focus:ring-2 focus:ring-electric-blue focus:border-transparent",
                "text-white placeholder-charcoal-400 transition-all duration-300",
                !isEmailValid && "opacity-50 cursor-not-allowed"
              )}
            />
            {isEmailValid && password && !isPasswordValid && (
              <p className="absolute text-xs text-amber-400 mt-1">
                Password must be at least 6 characters
              </p>
            )}
            <button
              type="button"
              onClick={onSwitchToReset}
              className={cn(
                "absolute right-3 top-1/2 -translate-y-1/2 text-xs",
                "transition-colors duration-200",
                error?.includes('invalid') || error?.includes('incorrect')
                  ? "text-electric-blue animate-pulse"
                  : "text-charcoal-400 hover:text-electric-blue"
              )}
            >
              Forgot?
            </button>
          </div>
        </div>

        {error && (
          <div className="flex items-center gap-2 text-red-400 text-sm">
            <AlertCircle className="w-4 h-4" />
            <p className="flex-1 font-medium">{error}</p>
          </div>
        )}
        {successMessage && (
          <div className="text-green-400 text-sm p-3 bg-green-400/10 border border-green-400/20 rounded-lg">
            {successMessage}
          </div>
        )}

        <button
          type="submit"
          disabled={isLoading || !isEmailValid || !isPasswordValid}
          className={cn(
            "w-full py-2.5 px-4 rounded-lg font-medium transition-colors",
            "disabled:opacity-50 disabled:cursor-not-allowed",
            "flex items-center justify-center",
            "bg-electric-blue hover:bg-electric-blue/90 text-charcoal-900",
            (!isEmailValid || !isPasswordValid) && "opacity-0"
          )}
        >
          {isLoading ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            'Sign In'
          )}
        </button>
      </form>
      
      <button
        onClick={onSwitchToSignUp}
        className={cn(
          "w-full text-center mt-4 text-electric-blue hover:underline text-sm transition-opacity duration-300",
          !isEmailValid && "opacity-50"
        )}
      >
        Need an account? Sign up
      </button>

      <div className="mt-6 relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-charcoal-600"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-charcoal-800/90 text-charcoal-400">Or continue with</span>
        </div>
      </div>

      <div className="mt-6">
        <AuthButtons />
      </div>
    </div>
  );
};