// @ts-nocheck
import React from 'react';
import { QuickAction } from './QuickAction';
import { Logo } from '../brand/Logo';
import { ChatInput } from './ChatInput';
import { useFileUpload } from '@/lib/hooks/useFileUpload';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { useChat } from '@/lib/hooks/useChat';
import { useCanvasStore } from '@/lib/stores/canvasStore';
import { useEffect } from 'react';

export const LandingView = () => {
  const { sendMessage } = useChat();
  const { type } = useCanvasStore();
  const {
    files,
    addFiles,
    removeFile,
    clearFiles
  } = useFileUpload();

  // Only show landing view for learning program type
  if (type === 'content-map') {
    return null;
  }

  const handleStartNewChat = async (message: string) => {
    await sendMessage(message, files.map(f => f.file));
  };

  const quickActions = [
    {
      icon: '🎯',
      label: 'Use your voice to share your challenges/goals',
      onClick: () => handleStartNewChat('I want to improve my team\'s performance through targeted learning programs'),
    },
    {
      icon: '📄',
      label: 'Design a program from this PDF',
      onClick: () => handleStartNewChat('I have a training document I\'d like to convert into a learning program'),
    },
    {
      icon: '📊',
      label: 'Identify skill gaps from this spreadsheet',
      onClick: () => handleStartNewChat('I need to analyze my team\'s skill gaps and create a development plan'),
    },
    {
      icon: '🌐',
      label: 'Build a path using this website',
      onClick: () => handleStartNewChat('Please help me create a learning path based on this resource'),
    },
    {
      icon: '🎓',
      label: 'Design a Pro Pathway',
      onClick: () => handleStartNewChat('I need a professional development pathway for my team'),
    },
    {
      icon: '🌍',
      label: 'Create an Empathetic Leadership pathway in Spanish',
      onClick: () => handleStartNewChat('Create a learning pathway focused on Empathetic Leadership for new managers in Spanish'),
    },
  ];

  return (
    <div className="h-full flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-3xl space-y-8">
        <div className="text-center space-y-4">
          <Logo size="lg" />
          <h1 className="text-2xl font-bold text-white">
            Let's shape your journey together!
          </h1>
          <p className="text-charcoal-300">
            Start by typing your learning goals or upload a document to get started
          </p>
        </div>

        <div className="space-y-4">
          <ChatInput 
            files={files}
            onRemoveFile={removeFile}
            onClearFiles={clearFiles}
            onAddFiles={addFiles}
            onSend={handleStartNewChat}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {quickActions.map((action, index) => (
            <QuickAction key={index} {...action} />
          ))}
        </div>
      </div>
    </div>
  );
};