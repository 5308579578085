// @ts-nocheck
import { AIMessage, AIModel } from '../types';
import { getAIResponse } from '../providers';
import { SYSTEM_CONTEXT, RESPONSE_FORMAT } from './prompts';
import { validateLearningPath } from './validator';
import { LearningPathResponse } from './types';
import { extractJsonFromString } from '@/lib/utils';
import { useChatListStore } from '@/lib/stores/chatListStore';

export async function generateLearningPath(
  requirements: string[],
  model: AIModel
): Promise<LearningPathResponse> {
  try {
    const messages: AIMessage[] = [
      {
        id: 'system-context',
        role: 'system',
        content: SYSTEM_CONTEXT,
        timestamp: new Date(),
        model
      },
      {
        id: 'requirements-context',
        role: 'system',
        content: 'Here is what the user needs. This will be in an array where each item will be their response to questions about their learning needs. Your task is to use whatever is provided to create the best path/s possible.',
        timestamp: new Date(),
        model
      },
      {
        id: 'user-requirements',
        role: 'user',
        content: JSON.stringify({ requirements }),
        timestamp: new Date(),
        model
      },
      {
        id: 'response-format',
        role: 'system',
        content: RESPONSE_FORMAT,
        timestamp: new Date(),
        model
      }
    ];

    const response = await getAIResponse(messages, model);
    let parsedResponse;
    try {
      parsedResponse = JSON.parse(response.content);
    } catch (error) {
      console.error('Failed to parse JSON response:', error);
      throw new Error('Invalid JSON response from AI');
    }

    // Handle both array and object responses
    const paths = Array.isArray(parsedResponse) ? parsedResponse : parsedResponse.learningPaths;
    console.log('Extracted paths:', paths);
    
    if (!paths || !Array.isArray(paths)) {
      console.error('Invalid learning paths format:', parsedResponse);
      throw new Error('Invalid learning paths format');
    }

    return paths;
  } catch (error) {
    console.error('Failed to generate learning path:', error);
    throw error;
  }
}