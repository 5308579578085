// @ts-nocheck
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Message } from './chatStore';
import { Requirement } from './requirementsStore';
import { auth } from '../firebase';
import { saveChat, updateChatData } from '../services/chat/persistence';
import { SkillTree, LearningPath } from '../types/ai';
import { generateId } from '../utils';

type CanvasType = 'learning-program' | 'content-map';

export interface Chat {
  id: string;
  title: string;
  archived?: boolean;
  deleted?: boolean;
  createdAt: Date;
  updatedAt: Date;
  messages: Message[];
  requirements: Requirement[];
  skillTree?: SkillTree;
  learningPaths?: LearningPath[];
  isExpanded?: boolean;
  canvasType?: CanvasType;
  status?: 'initializing' | 'active';
}

interface ChatListState {
  chats: Chat[];
  activeChat: string | null;
  sidebarVisible: boolean;
  currentCanvasType: CanvasType;
  createChat: () => string;
  deleteChat: (id: string) => void;
  setChats: (chats: Chat[]) => void;
  setActiveChat: (id: string | null) => void;
  updateChat: (id: string, updater: ((chat: Chat) => Chat) | Partial<Chat>) => void;
  toggleSidebar: () => void;
  setSidebarVisible: (visible: boolean) => void;
  resetCanvas: () => void;
  setCurrentCanvasType: (type: CanvasType) => void;
  getCanvasType: (chatId: string | null) => CanvasType;
}

export const useChatListStore = create<ChatListState>()(
  persist(
    (set, get) => ({
      chats: [] as Chat[],
      activeChat: null,
      sidebarVisible: false,
      currentCanvasType: 'learning-program',
      
      setChats: (chats) => set({ 
        chats: chats.filter(chat => !chat.deleted)
      }),

      createChat: () => {
        const id = generateId();
        const currentCanvasType = get().currentCanvasType;
        const userId = auth.currentUser?.uid;
        const newChat: Chat = {
          id,
          title: 'New Conversation',
          createdAt: new Date(),
          updatedAt: new Date(),
          archived: false,
          deleted: false,
          messages: [],
          requirements: [],
          status: 'initializing',
          canvasType: currentCanvasType,
          skillTree: undefined,
          learningPaths: undefined,
          isExpanded: false
        };

        if (userId) {
          saveChat(userId, newChat);
        }

        set(state => ({
          chats: [...state.chats, newChat],
          activeChat: id,
          sidebarVisible: false
        }));

        return id;
      },

      deleteChat: (id: string) => {
        set(state => ({
          chats: state.chats.map(chat => 
            chat.id === id ? { ...chat, archived: true, updatedAt: new Date() } : chat
          ),
          activeChat: state.activeChat === id ? null : state.activeChat
        }));
      },

      setActiveChat: (id: string | null) => set({ activeChat: id }),

      updateChat: (id: string, updater) => {
        const userId = auth.currentUser?.uid;
        set(state => {
          const newChats = state.chats.map(chat => {
            if (chat.id !== id) return chat;
            const updates = typeof updater === 'function' ? updater(chat) : updater;
            const updatedChat = { ...chat, ...updates, updatedAt: new Date() };
            
            if (userId) {
              updateChatData(userId, id, updatedChat);
            }
            
            return updatedChat;
          });
          return { chats: newChats };
        });
      },

      toggleSidebar: () => set(state => ({ sidebarVisible: !state.sidebarVisible })),

      setSidebarVisible: (visible: boolean) => set({ sidebarVisible: visible }),

      resetCanvas: () => {
        const activeChat = get().activeChat;
        if (activeChat) {
          set(state => ({
            chats: state.chats.map(chat => 
              chat.id === activeChat ? {
                ...chat,
                skillTree: undefined,
                learningPaths: undefined
              } : chat
            )
          }));
        }
      },

      setCurrentCanvasType: (type) => {
        set({ currentCanvasType: type });
        // If there's an initializing chat, update its type
        const { activeChat, chats } = get();
        if (activeChat) {
          const chat = chats.find(c => c.id === activeChat);
          if (chat?.status === 'initializing') {
            get().updateChat(activeChat, { canvasType: type });
          }
        }
      },

      getCanvasType: (chatId) => {
        if (!chatId) return get().currentCanvasType;
        const chat = get().chats.find(c => c.id === chatId);
        return chat?.canvasType || 'learning-program';
      }
    }),
    {
      name: 'chat-list-storage',
      partialize: (state) => ({
        chats: state.chats.map(chat => ({
          ...chat,
          canvasType: chat.canvasType || 'learning-program',
          createdAt: chat.createdAt.toISOString(),
          updatedAt: chat.updatedAt.toISOString()
        })),
        activeChat: state.activeChat,
        currentCanvasType: state.currentCanvasType
      }),
      merge: (persisted: any, current) => ({
        ...current,
        ...persisted,
        chats: (persisted.chats || []).map((chat: any) => ({
          ...chat,
          canvasType: chat.canvasType || 'learning-program',
          createdAt: new Date(chat.createdAt),
          updatedAt: new Date(chat.updatedAt)
        }))
      })
    }
  )
);