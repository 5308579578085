// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { Link2, Plus, CheckCircle2, AlertCircle } from 'lucide-react';
import { cn } from '@/lib/utils';
import { UdemyConnectionModal } from './UdemyConnectionModal';
import { useSettingsStore } from '@/lib/stores/settingsStore';
import { useUdemyStore } from '@/lib/stores/udemyStore';

interface Integration {
  type: 'url' | 'oauth' | 'graphql';
  name: string;
  description: string;
  fields: {
    name: string;
    type: string;
    required: boolean;
  }[];
}

const integrationTypes: Integration[] = [
  {
    type: 'url',
    name: 'Basic URL',
    description: 'Simple URL-based integration',
    fields: [
      { name: 'url', type: 'url', required: true },
      { name: 'apiKey', type: 'password', required: false },
    ],
  },
  {
    type: 'oauth',
    name: 'OAuth 2.0',
    description: 'Integration with client ID and secret',
    fields: [
      { name: 'url', type: 'url', required: true },
      { name: 'clientId', type: 'text', required: true },
      { name: 'clientSecret', type: 'password', required: true },
    ],
  },
  {
    type: 'graphql',
    name: 'GraphQL',
    description: 'GraphQL API integration',
    fields: [
      { name: 'endpoint', type: 'url', required: true },
      { name: 'apiKey', type: 'password', required: true },
    ],
  },
];

export const IntegrationsSettings = () => {
  const [showNewIntegration, setShowNewIntegration] = useState(false);
  const [selectedType, setSelectedType] = useState<Integration | null>(null);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const { isConnected: isUdemyConnected, domain, token, validateConnection, clearCredentials } = useUdemyStore();
  const { showUdemyModal, setShowSettings, activeTab } = useSettingsStore();

  // Validate connection on mount
  useEffect(() => {
    if (domain && token) {
      validateConnection(domain, token);
    }
  }, [domain, token, validateConnection]);

  // Handle domain/token changes
  useEffect(() => {
    if (!domain || !token) {
      clearCredentials();
    }
  }, [domain, token, clearCredentials]);

  const handleConfigure = useCallback(() => {
    setShowSettings(true, 'integrations', true);
  }, [setShowSettings]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // TODO: Handle integration submission
    console.log(formData);
    setShowNewIntegration(false);
    setSelectedType(null);
    setFormData({});
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Link2 className="w-6 h-6 text-electric-blue" />
          <h3 className="text-lg font-medium text-white">Integrations</h3>
        </div>
        <button
          onClick={() => setShowNewIntegration(true)}
          className="flex items-center gap-2 px-3 py-2 bg-electric-blue hover:bg-electric-blue/90 text-white rounded-lg transition-colors"
        >
          <Plus className="w-4 h-4" />
          Add Integration
        </button>
      </div>

      {showNewIntegration && (
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {integrationTypes.map((type) => (
              <button
                key={type.type}
                onClick={() => setSelectedType(type)}
                className={`p-4 rounded-lg border ${
                  selectedType?.type === type.type
                    ? 'border-electric-blue bg-electric-blue/10'
                    : 'border-gray-700 bg-gray-800 hover:border-electric-blue/50'
                } transition-colors`}
              >
                <h4 className="text-sm font-medium text-white">{type.name}</h4>
                <p className="text-sm text-gray-400 mt-1">{type.description}</p>
              </button>
            ))}
          </div>

          {selectedType && (
            <form onSubmit={handleSubmit} className="space-y-4">
              {selectedType.fields.map((field) => (
                <div key={field.name} className="space-y-2">
                  <label className="text-sm font-medium text-gray-300">
                    {field.name.charAt(0).toUpperCase() + field.name.slice(1)}
                    {field.required && <span className="text-red-500">*</span>}
                  </label>
                  <input
                    type={field.type}
                    required={field.required}
                    value={formData[field.name] || ''}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [field.name]: e.target.value,
                      }))
                    }
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-electric-blue focus:border-transparent text-white"
                  />
                </div>
              ))}
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowNewIntegration(false);
                    setSelectedType(null);
                  }}
                  className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-electric-blue hover:bg-electric-blue/90 text-white rounded-lg transition-colors"
                >
                  Add Integration
                </button>
              </div>
            </form>
          )}
        </div>
      )}

      {/* Predefined Integrations */}
      <div className="space-y-4">
        <div className="p-4 bg-gray-800 rounded-lg border border-gray-700">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <h4 className="text-sm font-medium text-white flex items-center gap-2">
                Udemy Business
                <span className={cn(
                  "text-xs px-2 py-0.5 rounded-full border flex items-center gap-1",
                  isUdemyConnected 
                    ? "bg-green-400/10 text-green-400 border-green-400/20"
                    : "bg-red-400/10 text-red-400 border-red-400/20"
                )}>
                  {isUdemyConnected ? (
                    <CheckCircle2 className="w-3 h-3" />
                  ) : (
                    <AlertCircle className="w-3 h-3" />
                  )}
                  {isUdemyConnected ? 'Connected' : 'Not Connected'}
                </span>
              </h4>
              <p className="text-sm text-gray-400">Enable learning path creation with Udemy Business content</p>
            </div>
            <button 
              onClick={handleConfigure}
              className={cn(
                "px-3 py-1.5 rounded-lg text-sm",
                "border transition-colors duration-200",
                isUdemyConnected
                  ? "border-electric-blue/30 text-electric-blue hover:bg-electric-blue/10"
                  : "bg-electric-blue text-charcoal-900 hover:bg-electric-blue/90"
              )}
            >
              {isUdemyConnected ? 'Configure' : 'Connect'}
            </button>
          </div>
        </div>
      </div>
      
      <UdemyConnectionModal 
        isOpen={showUdemyModal}
        onClose={() => setShowSettings(true, 'integrations', false)}
      />
    </div>
  );
};