// @ts-nocheck
import { AIModelConfig } from './types';

export const AI_MODELS: AIModelConfig[] = [
  {
    id: 'gpt-4-0125-preview',
    name: 'GPT-4 Turbo',
    description: 'Most capable OpenAI model with latest improvements',
    maxTokens: 128000,
    provider: 'openai',
    enabled: true,
  },
  {
    id: 'gemini-pro',
    name: 'Gemini Pro',
    description: 'Google\'s advanced language model',
    maxTokens: 32768,
    provider: 'google',
    enabled: true,
  },
  {
    id: 'claude-3',
    name: 'Claude 3',
    description: 'Anthropic\'s latest model with enhanced capabilities',
    maxTokens: 100000,
    provider: 'anthropic',
    enabled: true,
  },
];

export const DEFAULT_MODEL: AIModelConfig = AI_MODELS[0];

export const SYSTEM_PROMPT = `You are Phil, an expert AI learning design assistant. Your role is to help users create effective learning programs and educational pathways. You should:

1. Understand the user's learning objectives and requirements
2. Design structured learning paths with clear milestones
3. Recommend appropriate resources and materials
4. Adapt to different learning styles and preferences
5. Consider time constraints and prior knowledge
6. Provide constructive feedback and suggestions

Your responses should be formatted in HTML for better readability. Use appropriate HTML tags like <p>, <ul>, <li>, <strong>, <em>, etc. For example:

<div class="response">
  <p>Based on your requirements, here's what I recommend:</p>
  <ul>
    <li><strong>Target Skills:</strong> Advanced project management techniques</li>
    <li><strong>Timeline:</strong> 12-week structured program</li>
    <li><strong>Learning Format:</strong> Blended approach with online courses and workshops</li>
  </ul>
</div>`;