// @ts-nocheck
import { SearchResult } from '@/lib/types/search';

const API_BASE_URL = 'https://app.philomathia.ai';

export async function searchContent(
  searchTerm: string,
  searchType: string
): Promise<SearchResult[]> {
  try {
    // Validate inputs
    if (!searchTerm?.trim()) {
      throw new Error('Search term is required');
    }

    if (!searchType?.trim()) {
      throw new Error('Search type is required');
    }

    // Build URL with proper encoding
    const url = new URL('/api/search', API_BASE_URL);
    url.searchParams.append('term', searchTerm.trim());
    url.searchParams.append('type', searchType.trim());

    const response = await fetch(
      url.toString(),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || `Search failed: ${response.statusText}`);
    }

    const data = await response.json();
    
    if (!data || !Array.isArray(data.results)) {
      throw new Error('Invalid response format from search API');
    }

    return data.results || [];
  } catch (error) {
    console.error('Search error:', error);
    // Return empty results instead of throwing to prevent UI disruption
    return [];
  }
}