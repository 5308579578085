// @ts-nocheck
import React from 'react';
import { cn } from '@/lib/utils';

interface LogoProps {
  size?: 'sm' | 'md' | 'lg';
}

export const Logo: React.FC<LogoProps> = ({ size = 'md' }) => {
  const sizeClasses = {
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-3xl',
  };

  return (
    <div className={cn("flex items-center gap-3 font-bold", sizeClasses[size])}>
      <img 
        src="https://storage.udemy-futureworks.com/philomathiaIcon.svg" 
        alt="Phil"
        className={cn(
          "transition-all duration-200 hover:scale-105",
          size === 'sm' ? 'h-6 w-6' : size === 'lg' ? 'h-8 w-8' : 'h-7 w-7'
        )}
      />
      <span className={cn(
        "bg-gradient-to-r from-white via-electric-blue to-white",
        "bg-[length:200%_100%] bg-clip-text text-transparent",
        "animate-shimmer"
      )}>
        Philomath
      </span>
    </div>
  );
}