// @ts-nocheck
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  FacebookAuthProvider, 
  GithubAuthProvider,
  signInWithEmailAndPassword as firebaseSignInWithEmail,
  createUserWithEmailAndPassword as firebaseCreateUser,
  signOut as firebaseSignOut,
  sendPasswordResetEmail as firebaseSendPasswordReset,
  sendEmailVerification,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  User
} from 'firebase/auth';
import { auth } from './firebase';
import { handleUserAuth } from './services/auth/userAuth';
import { generateVerificationCode } from './utils';

export { auth };

export type { User };

export type { User };

// Provider instances
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const githubProvider = new GithubAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('Google sign in error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const signInWithGithub = async () => {
  try {
    const result = await signInWithPopup(auth, githubProvider);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('GitHub sign in error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const signInWithFacebook = async () => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('Facebook sign in error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const signInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const result = await firebaseSignInWithEmail(auth, email, password);
    localStorage.setItem('lastEmail', email);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('Email/Password sign in error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const createUserWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const result = await firebaseCreateUser(auth, email, password);
    // Send email verification
    await sendEmailVerification(result.user);
    // Initialize user profile
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('Sign up error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const sendPasswordResetEmail = async (email: string) => {
  try {
    await firebaseSendPasswordReset(auth, email);
  } catch (error: any) {
    console.error('Password reset error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const changePassword = async (currentPassword: string, newPassword: string) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No user logged in');
  if (!user.email) throw new Error('No email associated with account');
  
  try {
    // First reauthenticate
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);
    
    // Then update password
    await updatePassword(user, newPassword);
  } catch (error: any) {
    console.error('Password change error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    await handleUserAuth(null);
  } catch (error: any) {
    console.error('Sign out error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

const getAuthErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case 'auth/invalid-email':
      return 'Invalid email format. Please check and try again';
    case 'auth/user-disabled':
      return 'This account has been disabled. Please contact support';
    case 'auth/user-not-found':
      return 'No account exists with this email address';
    case 'auth/wrong-password':
      return 'Invalid email or password. Please try again';
    case 'auth/email-already-in-use':
      return 'This email is already registered. Please sign in instead';
    case 'auth/weak-password':
      return 'Password must be at least 6 characters';
    case 'auth/popup-closed-by-user':
      return 'Authentication window closed. Please try again';
    case 'auth/cancelled-popup-request':
      return 'Another sign in window is already open';
    case 'auth/operation-not-allowed':
      return 'This sign in method is currently unavailable';
    case 'auth/popup-blocked':
      return 'Pop-up blocked. Please enable pop-ups for this site';
    case 'auth/invalid-credential':
      return 'The email or password is incorrect';
    case 'auth/too-many-requests':
      return 'Too many attempts. Please try again in a few minutes';
    default:
      return 'Something went wrong. Please try again';
  }
};