// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { ChevronRight, Star, Award, TrendingUp, Loader2, MessageSquare, ChevronDown, X, BookOpen, PanelLeftClose, PanelLeftOpen } from 'lucide-react';

interface Skill {
  skill: string;
  subskills: Array<{
    subskill: string;
    level: string;
    dependencies: string[];
    description: string;
    learningBehaviors: string[];
  }>;
  level?: string;
  dependencies?: string[];
  description?: string;
}

interface SkillTreeProps {
  tree?: {
    Skills: Skill[];
  };
  isLoading?: boolean;
  collapsed?: boolean;
}

const LEVEL_COLORS = {
  beginner: 'from-green-500/20 to-green-600/20 border-green-500/30 text-green-400',
  intermediate: 'from-blue-500/20 to-blue-600/20 border-blue-500/30 text-blue-400',
  advanced: 'from-purple-500/20 to-purple-600/20 border-purple-500/30 text-purple-400'
};

const LEVEL_ICONS = {
  beginner: <Star className="w-4 h-4" />, // Star suggests a foundational or new beginning
  intermediate: <TrendingUp className="w-4 h-4" />, // TrendingUp represents growth or progress
  advanced: <Award className="w-4 h-4" /> // Award symbolizes mastery or high achievement
};

export function SkillTree({ tree, isLoading, collapsed = false }: SkillTreeProps = {}) {
  const [visibleSkills, setVisibleSkills] = useState<Skill[]>([]);
  const [expandedSkills, setExpandedSkills] = useState<string[]>([]);
  const [selectedSubskill, setSelectedSubskill] = useState<{
    subskill: string;
    behaviors: string[];
  } | null>(null);

  useEffect(() => {
    if (!tree) {
      setVisibleSkills([]);
      setExpandedSkills([]);
      return;
    }
    
    if (tree.Skills) {
      setExpandedSkills([]);
      setVisibleSkills([]);
      
      tree.Skills.forEach((skill, index) => {
        setTimeout(() => {
          setVisibleSkills(prev => [...prev, skill]);
        }, index * 150);
      });
    }
  }, [tree]);

  const toggleSkill = (skill: string) => {
    setExpandedSkills(prev => 
      prev.includes(skill)
        ? prev.filter(s => s !== skill)
        : [...prev, skill]
    );
  };

  if (isLoading) {
    return (
      <div className="space-y-4">
        {[1, 2, 3].map((_, i) => (
          <div 
            key={i}
            className={cn(
              "p-4 rounded-lg",
              "bg-charcoal-800/30 border border-charcoal-700/30",
              "animate-pulse"
            )}
          >
            <div className="flex items-center gap-3 mb-4">
              <div className="w-8 h-8 rounded-lg bg-charcoal-700/50" />
              <div className="flex-1 space-y-2">
                <div className="h-4 bg-charcoal-700/50 rounded w-3/4" />
                <div className="h-3 bg-charcoal-700/50 rounded w-1/2" />
              </div>
              <div className="flex-1 space-y-2">
                <div className="h-4 bg-charcoal-700/50 rounded w-3/4" />
                <div className="h-3 bg-charcoal-700/50 rounded w-1/2" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!tree || !tree.Skills || tree.Skills.length === 0) {
    return (
      <div className="text-center p-6 bg-charcoal-800/30 rounded-lg border border-charcoal-700/30">
        <div className="flex flex-col items-center gap-3">
          <MessageSquare className="w-8 h-8 text-charcoal-600" />
          <div>
            <p className="text-sm text-charcoal-400">
              Start chatting with Phil to generate your skill tree
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(
      "space-y-3 relative",
      "transition-all duration-300"
    )}>
      {visibleSkills.map((skillGroup, index) => (
        <div 
          key={index}
          className={cn(
            "rounded-lg overflow-hidden",
            "bg-gradient-to-br from-charcoal-800/30 to-charcoal-900/30",
            "border border-charcoal-700/30",
            "hover:border-electric-blue/20",
            "transition-all duration-500 ease-out",
            "opacity-0",
            collapsed ? "w-0 opacity-0 p-0 border-0" : "w-full opacity-100",
            collapsed && "pointer-events-none"
          )}
          style={{ 
            animation: 'skillFloatUp 0.5s ease-out forwards',
            animationDelay: `${index * 100}ms`
          }}
        >
          {/* Skill Header */}
          <button
            onClick={() => toggleSkill(skillGroup.skill)}
            className={cn(
              "w-full flex items-center p-2.5 hover:bg-charcoal-700/20",
              collapsed ? "justify-center" : "justify-between"
            )}
          >
            <div className="flex items-center gap-2">
              <div className={cn(
                "p-2 rounded-lg",
                "bg-gradient-to-r",
                skillGroup.level ? LEVEL_COLORS[skillGroup.level as keyof typeof LEVEL_COLORS] : "from-electric-blue/20 to-blue-500/20 border-electric-blue/30 text-electric-blue"
              )}>
                {skillGroup.level ? 
                  LEVEL_ICONS[skillGroup.level as keyof typeof LEVEL_ICONS] : 
                  <ChevronRight className="w-4 h-4" />
                }
              </div>
              <h4 className={cn(
                "text-sm font-medium text-white transition-opacity duration-300",
                collapsed ? "opacity-0 group-hover:opacity-100 whitespace-nowrap" : "opacity-100"
              )}>
                {skillGroup.skill}
              </h4>
            </div>
            
            <ChevronDown
              className={cn(
                "w-4 h-4 text-charcoal-400 transition-transform duration-200",
                expandedSkills.includes(skillGroup.skill) ? "transform rotate-180" : "",
                collapsed && "hidden"
              )}
            />
          </button>

          {/* Subskills */}
          {expandedSkills.includes(skillGroup.skill) && !collapsed && (
            <div className="px-3 pb-3">
              <div className="space-y-2 ml-2 pt-2">
                {skillGroup.subskills.map((subskill, subIndex) => (
                  <div 
                    key={subIndex}
                    className="opacity-0"
                    style={{ 
                      animation: 'subskillFloatUp 0.3s ease-out forwards',
                      animationDelay: `${subIndex * 100}ms`
                    }}
                  >
                    <div className={cn(
                      "flex items-center gap-2 p-3 rounded-lg",
                      "bg-charcoal-900/30 border border-charcoal-800/30",
                      "hover:border-electric-blue/20 group",
                      "transition-colors duration-200",
                      "text-sm cursor-pointer"
                    )}>
                      <div className={cn(
                        "p-1 rounded self-start mt-1.5",
                        "bg-gradient-to-r",
                        subskill.level ? LEVEL_COLORS[subskill.level as keyof typeof LEVEL_COLORS] : "from-electric-blue/20 to-blue-500/20 border-electric-blue/30 text-electric-blue"
                      )}>
                        {subskill.level ? 
                          LEVEL_ICONS[subskill.level as keyof typeof LEVEL_ICONS] : 
                          <ChevronRight className="w-3 h-3" />
                        }
                      </div>
                      <div className="flex-1 min-w-0" onClick={() => setSelectedSubskill({ subskill: subskill.subskill, behaviors: subskill.learningBehaviors })}>
                        <span className="text-white font-medium">{subskill.subskill}</span>
                        {subskill.description && (
                          <p className="text-xs text-charcoal-400 mt-1">{subskill.description}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
      
      {/* Learning Behaviors Overlay */}
      {selectedSubskill && (
        <div 
          className={cn(
            "fixed inset-0 bg-charcoal-950/90 backdrop-blur-sm z-50",
            "flex items-center justify-center p-4",
            "animate-in fade-in duration-200"
          )}
          onClick={() => setSelectedSubskill(null)}
        >
          <div 
            className={cn(
              "bg-charcoal-900 rounded-xl max-w-lg w-full",
              "border border-charcoal-800",
              "animate-in slide-in-from-bottom-4 duration-300"
            )}
            onClick={e => e.stopPropagation()}
          >
            <div className="flex items-center justify-between p-6 border-b border-charcoal-800">
              <div className="flex items-center gap-3">
                <BookOpen className="w-5 h-5 text-electric-blue" />
                <h3 className="text-lg font-medium text-white">{selectedSubskill.subskill}</h3>
              </div>
              <button 
                onClick={() => setSelectedSubskill(null)}
                className="p-2 text-charcoal-400 hover:text-white rounded-lg hover:bg-charcoal-800"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="p-6 space-y-4">
              {selectedSubskill.behaviors.map((behavior, index) => (
                <div key={index} className="flex items-start gap-3 text-charcoal-300">
                  <div className="w-1.5 h-1.5 rounded-full bg-electric-blue/50 mt-2 flex-shrink-0" />
                  <p>{behavior}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// Add custom keyframes for animations
const style = document.createElement('style');
style.textContent = `
  @keyframes skillFloatUp {
    from {
      opacity: 0;
      transform: translateY(1rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes subskillFloatUp {
    from {
      opacity: 0;
      transform: translateY(0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
document.head.appendChild(style);