// @ts-nocheck
import React, { useState } from 'react';
import { Github, Facebook, ChevronDown } from 'lucide-react';
import { signInWithGoogle, signInWithGithub, signInWithFacebook } from '@/lib/auth';
import { cn } from '@/lib/utils';

export const AuthButtons = () => {
  const [showOtherMethods, setShowOtherMethods] = useState(false);

  return (
    <div className="space-y-3 w-full">
      <button
        onClick={signInWithGoogle}
        className={cn(
          "w-full flex items-center justify-center gap-2 px-3 py-2.5",
          "bg-white text-gray-800 group",
          "rounded-lg transition-colors",
          "border border-gray-200 hover:border-gray-300 font-medium text-sm",
          "hover:shadow-lg hover:shadow-charcoal-950/5 transform hover:-translate-y-0.5",
          "transition-all duration-200"
        )}
      >
        <img 
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
          alt="Google" 
          className="w-5 h-5 transition-transform duration-200 group-hover:scale-110"
        />
        Google
      </button>

      <button
        onClick={() => setShowOtherMethods(!showOtherMethods)}
        className={cn(
          "w-full flex items-center justify-center gap-2 px-3 py-2",
          "text-charcoal-400 hover:text-charcoal-300",
          "text-sm transition-colors"
        )}
      >
        Other sign in methods
        <ChevronDown className={cn(
          "w-4 h-4 transition-transform duration-200",
          showOtherMethods && "transform rotate-180"
        )} />
      </button>

      <div className={cn(
        "grid gap-2 transition-all duration-200",
        showOtherMethods ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
      )}>
        <div className="overflow-hidden">
          <div className="grid grid-cols-2 gap-2 pt-1">
            <div className="col-span-1">
              <button
                onClick={signInWithGithub}
                className={cn(
                  "w-full flex items-center justify-center gap-2 px-3 py-2.5",
                  "bg-[#24292F] hover:bg-[#24292F]/90 text-white",
                  "rounded-lg transition-colors font-medium text-sm"
                )}
              >
                <Github className="w-5 h-5" />
                GitHub
              </button>
            </div>
            <div className="col-span-1">
              <button
                onClick={signInWithFacebook}
                className={cn(
                  "w-full flex items-center justify-center gap-2 px-3 py-2.5",
                  "bg-[#1877F2] hover:bg-[#1877F2]/90 text-white",
                  "rounded-lg transition-colors font-medium text-sm"
                )}
              >
                <Facebook className="w-5 h-5" />
                Facebook
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};