// @ts-nocheck
import React from 'react';
import { List, Grid, Download, Trash2, FileSpreadsheet, FileDown } from 'lucide-react';
import type { ViewMode } from './types';
import { cn } from '@/lib/utils';

interface TableHeaderProps {
  viewMode: ViewMode;
  onViewModeChange: (mode: ViewMode) => void;
  selectedCount: number;
  isInitializing?: boolean;
  onClearSelection: () => void;
}

export function TableHeader({ 
  viewMode, 
  onViewModeChange,
  selectedCount,
  isInitializing,
  onClearSelection
}: TableHeaderProps) {
  return (
    <div className="p-4 border-b border-charcoal-800/50 bg-charcoal-900/50">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          {/* View Mode Toggle */}
          <div className="flex items-center gap-2 bg-charcoal-800 rounded-lg p-1">
            <button
              onClick={() => onViewModeChange('text')}
              disabled={isInitializing}
              className={cn(
                "p-2 rounded-md transition-colors",
                viewMode === 'text'
                  ? "bg-electric-blue text-charcoal-900"
                  : "text-charcoal-400 hover:text-white",
                isInitializing && "opacity-50 cursor-not-allowed"
              )}
            >
              <List className="w-4 h-4" />
            </button>
            <button
              onClick={() => onViewModeChange('pretty')}
              disabled={isInitializing}
              className={cn(
                "p-2 rounded-md transition-colors",
                viewMode === 'pretty'
                  ? "bg-electric-blue text-charcoal-900"
                  : "text-charcoal-400 hover:text-white",
                isInitializing && "opacity-50 cursor-not-allowed"
              )}
            >
              <Grid className="w-4 h-4" />
            </button>
          </div>

          {/* Selection Info */}
          {selectedCount > 0 && (
            <div className="flex items-center gap-2">
              <span className="text-sm text-charcoal-400">
                {selectedCount} row{selectedCount !== 1 ? 's' : ''} selected
              </span>
              <button
                onClick={onClearSelection}
                className="text-charcoal-400 hover:text-white"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          )}
        </div>

        {/* Export Actions */}
        <div className="flex items-center gap-2">
          <button
            className={cn(
              "flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm",
              "bg-charcoal-800 text-charcoal-400",
              "hover:text-white hover:bg-charcoal-700",
              "transition-colors"
            )}
          >
            <FileSpreadsheet className="w-4 h-4" />
            Export CSV
          </button>
          <button
            className={cn(
              "flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm",
              "bg-charcoal-800 text-charcoal-400",
              "hover:text-white hover:bg-charcoal-700",
              "transition-colors"
            )}
          >
            <FileDown className="w-4 h-4" />
            Export PDF
          </button>
        </div>
      </div>

      {/* Analytics Bar */}
      <div className="mt-4 grid grid-cols-4 gap-4">
        <div className="bg-charcoal-800/50 rounded-lg p-4">
          <div className="text-sm text-charcoal-400">Total Rows</div>
          <div className="text-2xl font-bold text-white">100</div>
        </div>
        <div className="bg-charcoal-800/50 rounded-lg p-4">
          <div className="text-sm text-charcoal-400">Completed Searches</div>
          <div className="text-2xl font-bold text-electric-blue">0</div>
        </div>
        <div className="bg-charcoal-800/50 rounded-lg p-4">
          <div className="text-sm text-charcoal-400">Success Rate</div>
          <div className="text-2xl font-bold text-green-400">0%</div>
        </div>
        <div className="bg-charcoal-800/50 rounded-lg p-4">
          <div className="text-sm text-charcoal-400">Content Types</div>
          <div className="text-2xl font-bold text-purple-400">-</div>
        </div>
      </div>
    </div>
  );
}