// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { SkillTree } from './SkillTree';
import { LearningPath } from './LearningPath';
import { EditOverlay } from './EditOverlay';
import { useChatListStore } from '@/lib/stores/chatListStore'; 
import { useAuthStore } from '@/lib/store';
import { updateChatData } from '@/lib/services/chat/persistence';
import { generateLearningPath } from '@/lib/services/ai/learningPath';
import { cn } from '@/lib/utils';
import { Sparkles, Loader2, PanelLeftClose, PanelLeftOpen, Award } from 'lucide-react';

export async function handleGeneratePath(chatId: string) {
  const user = useAuthStore.getState().user;
  const updateChat = useChatListStore.getState().updateChat;
  const chat = useChatListStore.getState().chats.find(c => c.id === chatId);
  
  if (!chatId || !user || !chat) return;

  // Clear existing paths and set loading states
  updateChat(chatId, chat => ({
    ...chat,
    learningPaths: null,
    isLoadingPaths: true,
    isGeneratingPath: true
  }));
  
  try {
    const path = await generateLearningPath(chat.messages, 'gpt-4o');

    // Update local state
    updateChat(chatId, chat => ({
      ...chat,
      learningPaths: path,
      isLoadingPaths: false,
      isGeneratingPath: false
    }));
    
    // Update Firestore
    try {
      console.log('Saving learning paths to Firestore:', path);
      await updateChatData(user.uid, chatId, {
        learningPaths: path,
        isLoadingPaths: false,
        isGeneratingPath: false
      });
    } catch (error) {
      console.error('Failed to save learning paths to Firestore:', error);
    }

    return path;
  } catch (error) {
    console.error('Failed to generate learning path:', error);
    // Reset loading states on error
    updateChat(chatId, chat => ({
      ...chat,
      isLoadingPaths: false,
      isGeneratingPath: false
    }));
    throw error;
  }
}

export function Canvas() {
  const { activeChat, chats, updateChat } = useChatListStore();
  const user = useAuthStore(state => state.user);
  const chat = activeChat ? chats.find(c => c.id === activeChat) : null;
  const [isGeneratingSkills, setIsGeneratingSkills] = useState(false);
  const [isGeneratingPath, setIsGeneratingPath] = useState(false);
  const [isTextareaActive, setIsTextareaActive] = useState(false);
  const [isSkillTreeCollapsed, setIsSkillTreeCollapsed] = useState(false);
  const [isHoveringButton, setIsHoveringButton] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loadingPaths, setLoadingPaths] = useState(false);
  const activeSkillTree = activeChat ? chats.find(c => c.id === activeChat)?.skillTree : null;
  const activeLearningPaths = activeChat ? chats.find(c => c.id === activeChat)?.learningPaths : null;
  const editMode = !!activeLearningPaths;
  
  const toggleSkillTree = useCallback(() => {
    setIsSkillTreeCollapsed(prev => !prev);
  }, []);

  // Listen for textarea focus events
  useEffect(() => {
    const handleFocus = (e: FocusEvent) => {
      if (e.target instanceof HTMLTextAreaElement) {
        setIsTextareaActive(true);
      }
    };

    const handleBlur = (e: FocusEvent) => {
      if (e.target instanceof HTMLTextAreaElement) {
        setIsTextareaActive(false);
      }
    };

    document.addEventListener('focusin', handleFocus);
    document.addEventListener('focusout', handleBlur);

    return () => {
      document.removeEventListener('focusin', handleFocus);
      document.removeEventListener('focusout', handleBlur);
    };
  }, []);

  // Reset loading state when active chat changes
  useEffect(() => {
    if (activeChat) {
      setIsGeneratingSkills(true);
    }
  }, [activeChat]);

  // Update loading state when skill tree changes
  useEffect(() => {
    if (activeSkillTree) {
      setIsGeneratingSkills(false);
    }
  }, [activeSkillTree]);

  const generatePath = async () => {
    setIsGeneratingPath(true);
    
    try {
      if (activeChat) {
        await handleGeneratePath(activeChat);
      }
    } catch (error) {
      console.error('Failed to generate learning path:', error);
    } finally {
      setIsGeneratingPath(false);
    }
  };

  return (
    <div className="h-full bg-charcoal-900/50 overflow-hidden">
      <div className="h-full flex">
        {/* Main Learning Path Column */}
        <div className={cn(
          "flex-1 p-6 relative",
          editMode && isTextareaActive ? "overflow-hidden" : "overflow-y-auto"
        )}>
          {/* Toggle Button */}
          <button
            onClick={toggleSkillTree}
            onMouseEnter={() => setIsHoveringButton(true)}
            onMouseLeave={() => setIsHoveringButton(false)}
            className={cn( 
              "absolute right-0.5 top-1 z-10",
              "p-2 rounded-full",
              "bg-transparent",
              "text-charcoal-400 hover:text-electric-blue hover:border-electric-blue/30",
              "transition-all duration-100 ease-out",
              "transform hover:scale-105",
              "backdrop-blur-sm"
            )}
          >
            {isSkillTreeCollapsed ? (
              <PanelLeftOpen className="w-4 h-4" />
            ) : (
              <PanelLeftClose className="w-4 h-4" />
            )}
            {isHoveringButton && (
              <span className={cn(
                "absolute right-full mr-2 whitespace-nowrap",
                "px-2 py-1 rounded",
                "bg-charcoal-800 text-xs text-charcoal-300",
                "border border-charcoal-700/50",
                "animate-in fade-in slide-in-from-right-2 duration-75"
              )}>
                {isSkillTreeCollapsed ? 'Show Skills' : 'Hide Skills'}
              </span>
            )}
          </button>

          <div className="max-w-3xl mx-auto">
            {/* Generate Path Button */}
            {!editMode && (
              <button
                onClick={generatePath}
                disabled={isGeneratingPath || !activeChat}
                className={cn(
                  "group relative w-full mb-6",
                  "px-6 py-4 rounded-lg",
                  "bg-gradient-to-r from-electric-blue/10 to-blue-500/10",
                  "border border-electric-blue/20 hover:border-electric-blue/40",
                  "transition-all duration-300 ease-out",
                  "overflow-hidden",
                  "disabled:opacity-50 disabled:cursor-not-allowed"
                )}
              >
                {/* Animated background */}
                <div className={cn(
                  "absolute inset-0 opacity-0 group-hover:opacity-100",
                  "bg-gradient-to-r from-electric-blue/5 to-blue-500/5",
                  "transition-opacity duration-300"
                )} />

                {/* Button content */}
                <div className="relative flex items-center justify-center gap-3">
                  {isGeneratingPath ? (
                    <Loader2 className="w-5 h-5 text-electric-blue animate-spin" />
                  ) : (
                    <Sparkles className="w-5 h-5 text-electric-blue" />
                  )}
                  <span className="text-electric-blue font-medium">
                    {isGeneratingPath ? "Generating Learning Path..." : "Generate Learning Path"}
                  </span>
                </div>
              </button>
            )}

            <LearningPath 
              paths={activeLearningPaths} 
              isLoading={loadingPaths}
              isGeneratingPath={chat?.isGeneratingPath || false} 
            />
            
            {/* Edit Mode Overlay */}
            <EditOverlay 
              show={editMode && !!activeChat} 
              isTextareaActive={isTextareaActive}
              onVisibilityChange={setIsEditMode}
              onNewPath={() => {
                setEditMode(false);
                generatePath();
              }}
            />
          </div>
        </div>

        {/* Skill Tree - Right Side */}
        <div className={cn(
          "border-l border-charcoal-800 overflow-y-auto p-6 relative",
          "transition-all duration-300 ease-in-out transform",
          isSkillTreeCollapsed ? "w-0 p-0 border-l-0" : "w-80"
        )}>
          <div className={cn(
            "flex items-center gap-2 mb-4",
            "text-lg font-semibold text-white",
            isSkillTreeCollapsed ? "opacity-0" : "opacity-100",
            "transition-opacity duration-300"
          )}>
            <Award className="w-5 h-5 text-electric-blue" />
            Skills
          </div>
          <div className="space-y-4">
            <SkillTree 
              tree={activeSkillTree} 
              isLoading={isGeneratingSkills}
              collapsed={isSkillTreeCollapsed}
            />
          </div>
        </div>
      </div>
    </div>
  );
}