// @ts-nocheck
import Papa from 'papaparse';

export async function exportToCsv(learningPath: any) {
  // Prepare data in flat structure
  const rows = [];
  
  // Add header row
  rows.push([
    'Learning Path Name',
    'Learning Path Description',
    'Section Title',
    'Section Description',
    'Item Title',
    'Item Description', 
    'Item URL',
    'Item Type',
    'Item Search Term'
  ]);

  // Add data rows
  learningPath.sections.forEach((section: any) => {
    section.items.forEach((item: any) => {
      rows.push([
        learningPath.name,
        learningPath.description,
        section.title,
        section.description,
        item.title || '',
        item.description || '',
        item.url || '',
        item.searchType || '',
        item.searchTerm || ''
      ]);
    });
  });

  // Convert to CSV using PapaParse
  const csv = Papa.unparse(rows, {
    quotes: true, // Wrap all fields in quotes
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ",",
    header: false,
    newline: "\n"
  });

  // Create and download file
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${learningPath.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_learning_path.csv`;
  link.click();
  URL.revokeObjectURL(link.href);

  return { success: true };
}