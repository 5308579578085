// @ts-nocheck
import OpenAI from 'openai';
import type { Requirement } from '../stores/requirementsStore';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const REQUIREMENTS_PROMPT = `Extract learning requirements from the following message. Categorize them into:

- audience: Target audience details
- problem: Business/learning problems to solve
- objectives: Learning objectives and goals
- content: Content areas and topics
- constraints: Time, budget, or other constraints

Return a JSON array of requirements, each with:
{
  "requirements": [
    {
      "category": "audience|problem|objectives|content|constraints",
      "content": "The extracted requirement"
    }
  ]
}

Only include clear, explicit requirements. Do not make assumptions or infer requirements that aren't clearly stated.`;

export async function extractRequirements(message: string): Promise<Omit<Requirement, 'id' | 'timestamp'>[]> {
  try {
    console.log('Extracting requirements from message:', message);

    const response = await openai.chat.completions.create({
      model: 'gpt-4-0125-preview',
      messages: [
        { role: 'system', content: REQUIREMENTS_PROMPT },
        { role: 'user', content: message }
      ],
      response_format: { type: 'json_object' },
    });

    const content = response.choices[0]?.message?.content;
    console.log('AI response:', content);

    if (!content) {
      console.log('No content in AI response');
      return [];
    }

    const parsed = JSON.parse(content);
    console.log('Parsed requirements:', parsed.requirements);
    return parsed.requirements || [];
  } catch (error) {
    console.error('Failed to extract requirements:', error);
    return [];
  }
}