// @ts-nocheck
import { useEffect } from 'react';
import { useAuthStore } from '../store';
import { useChatListStore } from '../stores/chatListStore';
import { initializeUserProfile } from '../services/user/userProfile';
import { loadUserChats } from '../services/chat/persistence';

export function useSession() {
  const { user, initialized } = useAuthStore();
  const { setChats, createChat, setActiveChat } = useChatListStore();

  useEffect(() => {
    const initializeSession = async () => {
      if (user && initialized) {
        await initializeUserProfile(user);
        
        try {
          const chats = await loadUserChats(user.uid);
          setChats(chats);

          // Always create a new chat on session initialization
          const chatId = createChat();
          setActiveChat(chatId);
        } catch (error) {
          console.error('Failed to load chats:', error);
        }
      }
    };

    initializeSession();
  }, [user, initialized, setChats, createChat, setActiveChat]);

  return { user, initialized };
}