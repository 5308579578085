// @ts-nocheck
import React from 'react';
import { FileDown, Upload, FileSpreadsheet, MoreVertical, CheckCircle2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { exportToPdf, exportToCsv } from '@/lib/services/exports';
import { useState, useRef, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { useSettingsStore } from '@/lib/stores/settingsStore';
import { useUdemyStore } from '@/lib/stores/udemyStore';

interface LearningPathActionsProps {
  path: any;
}

export function LearningPathActions({ path }: LearningPathActionsProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { domain, token, isConnected } = useUdemyStore();
  const { setShowSettings } = useSettingsStore();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && 
          buttonRef.current && 
          !menuRef.current.contains(event.target as Node) &&
          !buttonRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCreateUdemyPath = async () => {
    if (!isConnected || !domain || !token) {
      setShowSettings(true, 'integrations', true);
      return;
    }

    // Validate connection first
    try {
      const validationResponse = await fetch('https://app.philomathia.ai/udemy/getEnvironmentStatus', {
        method: 'GET',
        headers: {
          'domain': domain,
          'Authorization': token
        }
      });

      if (!validationResponse.ok) {
        setShowSettings(true, 'integrations', true);
        return;
      }
    } catch (error) {
      setShowSettings(true, 'integrations', true);
      return;
    }
    setIsCreating(true);
    setError(null);

    try {
      const response = await fetch('https://app.philomathia.ai/api/createPath', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'domain': domain + '.udemy.com',
          'Authorization': `Bearer ${token}`,
          'isPublic': 'true'
        },
        body: JSON.stringify(path)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || 'Failed to create Udemy path');
      }

      const data = await response.json();
      console.log('Path created successfully:', data);
      
      setError(null);
      setIsSuccess(true);
      
      // Reset success state after 3 seconds
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Failed to create Udemy path:', error);
      setError(error instanceof Error ? error.message : 'Failed to create path in Udemy. Please try again.');
    } finally {
      setIsCreating(false);
    }
  };

  const handleExportPdf = async () => {
    try {
      console.log('Exporting path to PDF:', path);
      await exportToPdf(path);
    } catch (error) {
      console.error('Failed to export to PDF:', error);
    }
  };

  const handleExportCsv = async () => {
    try {
      console.log('Exporting path to CSV:', path);
      await exportToCsv(path);
    } catch (error) {
      console.error('Failed to export to CSV:', error);
    }
  };

  return (
    <div className="flex items-center justify-between mb-6">
      <h3 className={cn(
        "text-2xl font-bold",
        "bg-gradient-to-r from-white via-electric-blue to-white",
        "bg-[length:200%_100%] bg-clip-text text-transparent",
        "animate-shimmer"
      )}>{path.name}</h3>
      
      <div className="flex items-center gap-3">
        {/* Primary Action: Export to Udemy */}
        <div>
          <button
            onClick={handleCreateUdemyPath}
            disabled={isCreating}
            className={cn(
              "flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm",
              isSuccess 
                ? "bg-green-400/10 border-green-400/30 text-green-400"
                : "bg-electric-blue/10 hover:bg-electric-blue/20 border-electric-blue/30 hover:border-electric-blue/50 text-electric-blue",
              "border font-medium",
              "transition-all duration-200",
              "disabled:opacity-50 disabled:cursor-not-allowed"
            )}
          >
            {isSuccess ? (
              <>
                <CheckCircle2 className="w-4 h-4" />
                Path Created
              </>
            ) : isCreating ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                Creating...
              </>
            ) : (
              <>
                <Upload className="w-4 h-4" />
                Create in Udemy
              </>
            )}
          </button>
          {error && (
            <div className="absolute mt-2 p-2 text-xs text-red-400 bg-red-400/10 border border-red-400/20 rounded">
              {error}
            </div>
          )}
        </div>

        {/* Secondary Actions Menu */}
        <div className="relative">
          <button
            ref={buttonRef}
            onClick={() => setShowMenu(!showMenu)}
            className={cn(
              "p-2 rounded-lg",
              "text-charcoal-400 hover:text-white",
              "hover:bg-charcoal-800/50",
              "transition-colors duration-200"
            )}
          >
            <MoreVertical className="w-5 h-5" />
          </button>

          {showMenu && (
            <div
              ref={menuRef}
              className={cn(
                "absolute right-0 top-full mt-2",
                "w-48 py-1 rounded-lg",
                "bg-charcoal-800 border border-charcoal-700",
                "shadow-lg",
                "z-50"
              )}
            >
              <button
                onClick={handleExportPdf}
                className={cn(
                  "w-full flex items-center gap-3 px-4 py-2",
                  "text-charcoal-300 hover:text-white hover:bg-charcoal-700",
                  "transition-all duration-200"
                )}
              >
                <FileDown className="w-4 h-4" />
                Export PDF
              </button>
              <button
                onClick={handleExportCsv}
                className={cn(
                  "w-full flex items-center gap-3 px-4 py-2",
                  "text-charcoal-300 hover:text-white hover:bg-charcoal-700",
                  "transition-all duration-200"
                )}
              >
                <FileSpreadsheet className="w-4 h-4" />
                Export CSV
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}