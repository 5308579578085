// @ts-nocheck
import React, { useState } from 'react';
import { ContentTable } from './ContentTable';
import { TableHeader } from './TableHeader';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { useCanvasStore } from '@/lib/stores/canvasStore';
import { cn } from '@/lib/utils';
import type { ViewMode } from './types';

const DEFAULT_VIEW_MODE: ViewMode = 'text';

export function Canvas() {
  const [viewMode, setViewMode] = useState<ViewMode>(DEFAULT_VIEW_MODE);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const { activeChat, chats, updateChat } = useChatListStore();
  const { type } = useCanvasStore();
  
  const chat = activeChat ? chats.find(c => c.id === activeChat) : null;
  const isInitializing = chat?.status === 'initializing';

  return (
    <div className="h-full flex flex-col bg-charcoal-950">
      <TableHeader 
        viewMode={viewMode}
        onViewModeChange={setViewMode}
        selectedCount={selectedRows.size}
        onClearSelection={() => setSelectedRows(new Set())}
        isInitializing={isInitializing}
      />
      <div className="flex-1 overflow-hidden">
        <ContentTable 
          viewMode={viewMode}
          selectedRows={selectedRows}
          onSelectRows={setSelectedRows}
          isInitializing={isInitializing}
        />
      </div>
    </div>
  );
}