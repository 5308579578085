// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import { Logo } from '../brand/Logo';
import { useCanvasStore } from '@/lib/stores/canvasStore';
import { useRequirementsStore } from '@/lib/stores/requirementsStore';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { cn } from '@/lib/utils';
import { UserMenu } from './UserMenu';
import { ChevronDown, Sparkles, Menu, LayoutGrid, BookOpen, Lock } from 'lucide-react';
import { RequirementsList } from '../canvas/RequirementsList';

interface TopNavProps {
  onShowSettings: () => void;
}

const CANVAS_TYPE_ICONS = {
  'learning-program': {
    icon: BookOpen,
    label: 'Learning Program',
    color: 'text-purple-400'
  },
  'content-map': {
    icon: LayoutGrid,
    label: 'Content Map',
    color: 'text-blue-400'
  }
} as const;

export function TopNav({ onShowSettings }: TopNavProps) {
  const { title, leftPanelWidth } = useCanvasStore();
  const requirements = useRequirementsStore((state) => state.requirements);
  const { activeChat, chats } = useChatListStore();
  const [showRequirements, setShowRequirements] = React.useState(false);
  const requirementsRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { toggleSidebar } = useChatListStore();
  const { type } = useCanvasStore();
  const showTitle = activeChat && chats.find(c => c.id === activeChat)?.messages?.length > 0;
  const chat = activeChat ? chats.find(c => c.id === activeChat) : null;
  const canvasType = chat?.canvasType || type;
  const canvasInfo = CANVAS_TYPE_ICONS[canvasType as keyof typeof CANVAS_TYPE_ICONS];
  const isInitializing = chat?.status === 'initializing';

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (showRequirements && 
          requirementsRef.current && 
          buttonRef.current && 
          !requirementsRef.current.contains(event.target as Node) &&
          !buttonRef.current.contains(event.target as Node)) {
        setShowRequirements(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showRequirements]);

  const displayTitle = title || 'Learning Program Design';

  return (
    <nav className="flex-shrink-0 bg-charcoal-900/95 backdrop-blur-sm border-b border-charcoal-800/50 sticky top-0 z-50">
      <div className="h-16 px-6">
        <div className="flex items-center h-full">
          {/* Left side: Menu and Logo */}
          <div className="flex items-center gap-4 w-[200px]">
            <button
              onClick={toggleSidebar}
              className="p-2.5 text-charcoal-400 hover:text-white rounded-lg hover:bg-charcoal-800/50 transition-colors"
            >
              <Menu className="w-5 h-5" />
            </button>
            
            <Logo />
            
            {/* Canvas Type Badge */}
            {canvasInfo && (
              <div className={cn(
                "flex items-center gap-2 ml-8 w-fit",
                isInitializing && "animate-pulse"
              )}>
                <div className="flex items-center gap-2">
                  <canvasInfo.icon className={cn("w-4 h-4", canvasInfo.color)} />
                  <span className="text-sm text-charcoal-400 whitespace-nowrap">{canvasInfo.label}</span>
                  {isInitializing ? (
                    <span className="text-xs text-charcoal-500 ml-1">(Setup)</span>
                  ) : (
                    <Lock className="w-3.5 h-3.5 text-charcoal-500 opacity-50" />
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Center: Canvas Title */}
          <div className="flex-1 flex justify-center">
            <div 
              className={cn(
                "relative flex items-center transition-all duration-500 ease-in-out transform",
                !showTitle && "opacity-0 translate-y-4 pointer-events-none",
                showTitle && "opacity-100 translate-y-0"
              )}
              style={{
                marginLeft: `calc(${leftPanelWidth}% - 60px)`, 
                width: `${100 - leftPanelWidth}%`,
              }}
            >
              <button
                ref={buttonRef}
                onClick={() => setShowRequirements(!showRequirements)}
                className={cn(
                  "group flex items-center gap-3 px-4 py-2 rounded-lg w-full",
                  "hover:bg-charcoal-800/50",
                  "transition-all duration-200"
                )}
              >
                <div className={cn(
                  "p-2 rounded-lg",
                  "bg-gradient-to-r from-purple-500/10 to-blue-500/10",
                  "border border-purple-500/20 group-hover:border-purple-500/30",
                  "transition-all duration-200 group-hover:scale-105"
                )}>
                  <Sparkles className="w-5 h-5 text-purple-400" />
                </div>
                
                <div className="flex flex-col items-start">
                  <h2 className={cn(
                    "text-xl font-bold",
                    "bg-gradient-to-r from-purple-400 via-electric-blue to-purple-400",
                    "bg-[length:200%_100%] bg-clip-text text-transparent",
                    "animate-shimmer",
                    !title && "opacity-50"
                  )}>
                    {displayTitle}
                  </h2>
                </div>
              </button>
            </div>
          </div>

          {/* Right side: User Menu */}
          <div className="w-[200px] flex justify-end items-center gap-4">
            <UserMenu onShowSettings={onShowSettings} />
          </div>
        </div>
      </div>

      {/* Requirements Panel */}
      <div 
        ref={requirementsRef}
        className={cn(
          "absolute top-full left-0 right-0",
          "bg-charcoal-900/95 backdrop-blur-sm",
          "border-b border-charcoal-800/50",
          "transform transition-all duration-300 ease-in-out",
          showRequirements ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4 pointer-events-none"
        )}
      >
        <div className="max-w-7xl mx-auto">
          <RequirementsList />
        </div>
      </div>
    </nav>
  );
}