// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { cn } from '@/lib/utils';
import { searchContent } from '@/lib/services/search';
import { Loader2, ArrowRight, ArrowLeft, Search, X } from 'lucide-react';
import { LearningPathActions } from './LearningPathActions';
import debounce from 'lodash/debounce';

// Global variable for debugging and as a single source of truth
declare global {
  interface Window {
    selectedPath: LearningPath | null;
  }
}

export interface LearningPathItem {
  searchTerm: string;
  searchType: string;
  id?: string;
  lectures?: {
    totalLectures: number;
    numberSelectedLectures: number;
    selectedLectures: {
      items: [string, number][];
      select_all: boolean;
    };
  };
  title?: string;
  description?: string;
  url?: string;
  image?: string;
}

export interface LearningPathSection {
  title: string;
  description: string;
  items: LearningPathItem[];
}

export interface LearningPath {
  name: string;
  description: string;
  sections: LearningPathSection[];
}

export interface LearningPathProps {
  paths?: LearningPath[];
  isLoading?: boolean;
  isGeneratingPath?: boolean;
}

interface SearchState {
  results: any[];
  currentIndex: number;
  isLoading: boolean;
  error?: string;
  searchTerm: string;
  lastValidSearch?: string;
  searchType?: string;
}

// Generate a unique key for each search input
const getSearchKey = (sectionIndex: number, itemIndex: number) =>
  `section-${sectionIndex}-item-${itemIndex}`;

// Parse section and item index from the key
function parseKey(key: string): { sectionIndex: number; itemIndex: number } {
  const match = key.match(/^section-(\d+)-item-(\d+)$/);
  if (!match) {
    throw new Error("Invalid key format");
  }
  return {
    sectionIndex: parseInt(match[1], 10),
    itemIndex: parseInt(match[2], 10),
  };
}

export const LearningPath: React.FC<LearningPathProps> = ({ paths, isLoading, isGeneratingPath } = {}) => {
  const [searchStates, setSearchStates] = useState<Record<string, SearchState>>({});
  const [error, setError] = useState<string | null>(null);
  const [selectedPathIndex, setSelectedPathIndex] = useState<number | null>(null);
  const [selectedCourses, setSelectedCourses] = useState<Record<string, any>>({});
  const [selectedPathData, setSelectedPathData] = useState<LearningPath | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);

  // Clear path data when loading state changes
  useEffect(() => {
    if (isLoading) {
      setSelectedPathIndex(null);
      setSelectedPathData(null);
      window.selectedPath = null;
      setSelectedCourses({});
      setSearchStates({});
    }
  }, [isLoading]);

  // Sync selectedPathData with window.selectedPath for global debugging
  useEffect(() => {
    window.selectedPath = selectedPathData || null;
    if (window.selectedPath) {
    }
  }, [selectedPathData]);

  // Auto-select if there's only one path
  useEffect(() => {
    if (!isLoading && paths && paths.length === 1) {
      setSelectedPathIndex(0);
      const cloned = JSON.parse(JSON.stringify(paths[0]));
      window.selectedPath = cloned;
      setSelectedPathData(cloned);
    }
  }, [paths, isLoading]);

  const selectedPath = selectedPathData;

  // Debug logging
  useEffect(() => {

  }, [paths, selectedPathData, selectedPathIndex]);

  // Initialize search states for each item in selected path
  useEffect(() => {
    if (selectedPath?.sections) {
      // Add slight delay before initializing searches
      const timer = setTimeout(() => {
      const initialStates: Record<string, SearchState> = {};
      selectedPath.sections.forEach((section, sectionIndex) => {
        if (section.items) {
          section.items.forEach((item, itemIndex) => {
            const key = getSearchKey(sectionIndex, itemIndex);
            initialStates[key] = {
              results: [],
              currentIndex: 0,
              isLoading: false,
              searchTerm: item.searchTerm || '',
              searchType: item.searchType
            };

            // Trigger initial search if searchTerm exists
            if (item.searchTerm) {
              handleSearch(key, item.searchTerm, item.searchType);
            }
          });
        }
      });
      setSearchStates(initialStates);
      }, 1000); // 1 second delay
      
      return () => clearTimeout(timer);
    }
  }, [selectedPath?.sections]);

  const handleSearch = useCallback(async (key: string, term: string, type: string) => {
    if (!term || term.length < 2) {
      setSearchStates(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          results: [],
          currentIndex: 0,
          isLoading: false,
          error: term ? 'Search term must be at least 2 characters' : undefined,
          searchTerm: term,
        },
      }));
      return;
    }

    setSearchStates(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        isLoading: true,
        error: undefined,
        searchTerm: term,
      },
    }));

    try {
      const results = await searchContent(term, type);
      setSearchStates(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          results,
          currentIndex: 0,
          isLoading: false,
          lastValidSearch: term,
          searchTerm: term,
        },
      }));
      setError(null);

      // If we got results, immediately select the first one
      if (results.length > 0) {
        const { sectionIndex, itemIndex } = parseKey(key);
        updateSelectedCourse(sectionIndex, itemIndex, results[0]);
      }

    } catch (err) {
      console.error('Search error:', err);
      setSearchStates(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          results: [],
          isLoading: false,
          error: 'Failed to fetch results',
          searchTerm: term,
        },
      }));
      setError('An error occurred while searching. Please try again.');
    }
  }, []);

  const debouncedSearch = useCallback(
    debounce((key: string, term: string, type: string) => {
      handleSearch(key, term, type);
    }, 300),
    [handleSearch]
  );

  const navigateResults = useCallback((key: string, direction: 'next' | 'prev') => {
    setSearchStates(prev => {
      const state = prev[key];
      if (!state?.results.length) return prev;

      const newIndex = direction === 'next'
        ? (state.currentIndex + 1) % state.results.length
        : (state.currentIndex - 1 + state.results.length) % state.results.length;

      const newState = {
        ...prev,
        [key]: {
          ...state,
          currentIndex: newIndex,
        },
      };

      // After updating the index, immediately update the selected course
      const currentResult = newState[key].results[newIndex];
      if (currentResult) {
        const { sectionIndex, itemIndex } = parseKey(key);
        updateSelectedCourse(sectionIndex, itemIndex, currentResult);
      }

      return newState;
    });
  }, []);

  const updateSelectedCourse = (sectionIndex: number, itemIndex: number, result: any) => {
    const key = getSearchKey(sectionIndex, itemIndex);

    // Get lectures for the course if it's a Udemy course
    const getLectures = async (courseId: string) => {
      try {
        const response = await fetch('https://app.philomathia.ai/api/getRecommendedLectures', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            requirements: "Course content optimization",
            courseID: courseId
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch lectures');
        }

        const data = await response.json();
        return {
          lectures: JSON.stringify(data.selectedLectures),
          lecturesMeta: {
            totalLectures: data.totalLectures,
            numberSelectedLectures: data.numberSelectedLectures
          }
        };
      } catch (error) {
        console.error('Failed to fetch lectures:', error);
        return null;
      }
    };

    const searchState = searchStates[key];
    const courseData = {
      searchTerm: searchState?.searchTerm || result.searchTerm || '',
      searchType: searchState?.searchType || result.searchType || 'course',
      id: result.id,
      title: result.title,
      description: result.headline,
      url: result.url?.startsWith('http') ? result.url : `https://udemy.com${result.url}`,
      image: result.image_480x270,
      lectures: null,
      lecturesMeta: null
    };

    // If it's a Udemy course, fetch lectures
    if (result.id && courseData.url.includes('udemy.com')) {
      getLectures(result.id.toString()).then(lectures => {
        if (lectures) {
          courseData.lectures = lectures.lectures;
          courseData.lecturesMeta = lectures.lecturesMeta;
        }
        updatePathWithCourse(sectionIndex, itemIndex, courseData);
      });
    } else {
      updatePathWithCourse(sectionIndex, itemIndex, courseData);
    }
  };

  const updatePathWithCourse = (sectionIndex: number, itemIndex: number, courseData: any) => {
    const key = getSearchKey(sectionIndex, itemIndex);

    // Update selectedCourses state
    setSelectedCourses(prev => ({
      ...prev,
      [key]: courseData
    }));

    // Mutate window.selectedPath directly
    if (window.selectedPath) {
      window.selectedPath.sections[sectionIndex].items[itemIndex] = {
        ...window.selectedPath.sections[sectionIndex].items[itemIndex],
        ...courseData
      };
      // Update local state from global
      setSelectedPathData({ ...window.selectedPath });
      console.log('Full updated path:', window.selectedPath);
    }
  };

  const handleCourseSelect = (sectionIndex: number, itemIndex: number, result: any) => {
    console.log('Course selected by click:', { sectionIndex, itemIndex, result });
    updateSelectedCourse(sectionIndex, itemIndex, result);
  };

  const removeCourse = (sectionIndex: number, itemIndex: number) => {
    const key = getSearchKey(sectionIndex, itemIndex);

    // Remove from selected courses
    setSelectedCourses(prev => {
      const newState = { ...prev };
      delete newState[key];
      return newState;
    });

    // Revert item to original
    if (paths && window.selectedPath && selectedPathIndex !== null) {
      const originalItem = paths[selectedPathIndex].sections[sectionIndex].items[itemIndex];
      window.selectedPath.sections[sectionIndex].items[itemIndex] = {
        ...originalItem
      };
      setSelectedPathData({ ...window.selectedPath });
    }

    // Reset search state
    setSearchStates(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        results: [],
        currentIndex: 0,
        searchTerm: '',
      }
    }));
  };

  if (isLoading || isGeneratingPath) {
    return (
      <div className="space-y-6 animate-in fade-in slide-in-from-bottom-4 duration-300">
        <div className={cn(
          "bg-gradient-to-br from-charcoal-800/40 to-charcoal-800/20",
          "rounded-xl p-8 border border-charcoal-700/30",
          "relative overflow-hidden shimmer",
          "animate-in fade-in slide-in-from-bottom-4 duration-300",
          "animate-[pulse-glow_7s_ease-in-out_infinite]"
        )}>
          
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-lg bg-charcoal-700/50 shimmer" />
              <div className="h-8 w-48 bg-charcoal-700/50 rounded shimmer" />
            </div>
            <div className="flex gap-2">
              <div className="h-8 w-24 bg-charcoal-700/50 rounded-lg shimmer" />
              <div className="h-8 w-8 bg-charcoal-700/50 rounded-lg shimmer" />
            </div>
          </div>
          <div className="h-4 w-3/4 bg-charcoal-700/50 rounded shimmer" />
        </div>

        {/* Sections Shimmer */}
        {[1, 2, 3].map((_, i) => (
          <div 
            key={i}
            className={cn(
              "relative pl-8 mt-4 space-y-4",
              "animate-in fade-in slide-in-from-bottom-4",
              "before:absolute before:left-4 before:top-4 before:bottom-0 before:w-0.5 before:bg-gradient-to-b before:from-electric-blue/20 before:to-charcoal-700/30"
            )}
            style={{
              animationDelay: `${i * 100}ms`,
              animationDuration: '500ms'
            }}
          >
            {/* Section Number Circle */}
            <div className="absolute left-0 top-4 flex items-center justify-center z-10">
              <div className="w-8 h-8 rounded-full bg-charcoal-800/50 border border-charcoal-700/30 shimmer">
                <div className="h-4 w-4 bg-charcoal-700/50 rounded" />
              </div>
            </div>

            <div className="pl-8 py-2">
              <div className="space-y-4">
                <div className="h-6 w-64 bg-charcoal-700/50 rounded shimmer" />
                <div className="h-4 w-96 bg-charcoal-700/50 rounded shimmer" />
                
                <div className="space-y-4 mt-6">
                  {[1, 2].map((_, j) => (
                    <div 
                      key={j}
                      className={cn(
                        "flex items-start gap-4 p-4 shimmer",
                        "bg-charcoal-800/30 rounded-lg",
                        "animate-in fade-in slide-in-from-bottom-4",
                        "animate-[pulse-glow_12s_ease-in-out_infinite]"
                      )}
                      style={{
                        animationDelay: `${(i * 100) + (j * 75)}ms`,
                        animationDuration: '500ms'
                      }}
                    >
                      <div className="w-36 h-24 bg-charcoal-700/50 rounded shimmer" />
                      <div className="flex-1 space-y-3">
                        <div className="h-5 bg-charcoal-700/50 rounded w-3/4 shimmer" />
                        <div className="h-4 bg-charcoal-700/50 rounded w-1/2 shimmer" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!paths?.length) {
    return (
      <div className="text-center p-4 rounded-lg bg-charcoal-800/20 border border-charcoal-700/20">
        <p className="text-charcoal-400">No learning paths available</p>
      </div>
    );
  }

  if (!selectedPath) {
    return (
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {paths.map((path, index) => (
            <button
              key={index}
              onClick={() => {
                setSelectedPathIndex(index);
                const cloned = JSON.parse(JSON.stringify(paths[index]));
                window.selectedPath = cloned;
                setSelectedPathData(cloned);
              }}
              className={cn(
                "p-4 text-left rounded-lg transition-all duration-200",
                "bg-charcoal-800/20 border border-charcoal-700/20",
                "hover:border-electric-blue/30 hover:bg-charcoal-800/30",
                "group relative overflow-hidden"
              )}
            >
              <div className={cn(
                "absolute inset-0 bg-gradient-to-br from-electric-blue/5 to-transparent",
                "opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              )} />
              <div className="relative z-10">
                <h3 className="text-lg font-medium text-white mb-2">{path.name}</h3>
                <p className="text-sm text-charcoal-300 line-clamp-2">{path.description}</p>
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
    <div className={cn(
      "space-y-8",
      isEditMode && "overflow-hidden"
    )}>
      {/* Path Selector Buttons */}
      {paths.length > 1 && (
        <div className="flex flex-wrap gap-3 mb-6">
          {paths.map((path, index) => (
            <button
              key={index}
              onClick={() => {
                setSelectedPathIndex(index);
                const cloned = JSON.parse(JSON.stringify(paths[index]));
                window.selectedPath = cloned;
                setSelectedPathData(cloned);
              }}
              className={cn(
                "relative px-4 py-2 rounded-lg font-medium text-sm transition-all duration-200",
                "overflow-hidden group",
                selectedPathIndex === index
                  ? "bg-electric-blue/20 text-electric-blue border border-electric-blue/30"
                  : "bg-charcoal-800/50 text-charcoal-300 hover:text-electric-blue border border-charcoal-700/30 hover:border-electric-blue/30"
              )}
            >
              <div className={cn(
                "absolute inset-0 bg-gradient-to-r from-electric-blue/10 to-transparent",
                "opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              )} />
              <span className="relative z-10">
                {path.name || `Path ${index + 1}`}
              </span>
            </button>
          ))}
        </div>
      )}

      {/* Selected Path Content */}
      <div className={cn(
        "relative overflow-hidden",
        "bg-gradient-to-br from-charcoal-800/40 to-charcoal-800/20",
        "rounded-xl p-8 border border-charcoal-700/30",
        "backdrop-blur-sm"
      )}>
        {/* Background glow effect */}
        <div className="absolute -top-1/2 -right-1/2 w-[500px] h-[500px] bg-electric-blue/5 rounded-full blur-[100px]" />
        
        {/* Content */}
        <div className="relative z-10">
        <LearningPathActions path={selectedPath} />
        <p className={cn(
          "text-charcoal-300/90 leading-relaxed",
          "max-w-3xl"
        )}>{selectedPath.description}</p>
        </div>
      </div>

      {selectedPath.sections.map((section, sectionIndex) => (
        <div
          key={sectionIndex}
          className={cn(
            "relative pl-8 mt-4 space-y-4",
            "before:absolute before:left-4 before:top-4 before:bottom-0 before:w-0.5",
            "before:bg-gradient-to-b before:from-electric-blue/20 before:to-charcoal-700/30",
            sectionIndex === 0 && "mt-0"
          )}
          style={{ animationDelay: `${sectionIndex * 150}ms` }}
        >
          {/* Section Number Circle with Line */}
          <div className="absolute left-0 top-4 flex items-center justify-center z-10">
            <div className="w-8 h-8 rounded-full bg-charcoal-800 border border-charcoal-700 flex items-center justify-center">
              <span className="text-sm font-medium text-white">{sectionIndex + 1}</span>
            </div>
            <div className="absolute left-8 w-4 h-0.5 bg-charcoal-700" />
          </div>

          <div className={cn(
            'pl-8 py-2',
            'animate-fade-in group/section'
          )}>
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-white mb-2">
                {section.title}
              </h3>
              <p className="text-charcoal-300 mb-4">{section.description}</p>

              <div className="space-y-4">
                {section.items?.map((item, itemIndex) => {
                  const searchKey = getSearchKey(sectionIndex, itemIndex);
                  const searchState = searchStates[searchKey] || {
                    results: [],
                    currentIndex: 0,
                    isLoading: false,
                    searchTerm: item.searchTerm || '',
                    searchType: item.searchType
                  };
                  const currentResult = searchState.results[searchState.currentIndex];
                  const selectedCourse = selectedCourses[searchKey];

                  return (
                    <div
                      key={itemIndex}
                      className="relative"
                    >
                      {/* Search Controls */}
                      <div className={cn(
                        "flex items-center gap-2",
                        "h-0 opacity-0 overflow-hidden",
                        "group-hover/section:h-10 group-hover/section:opacity-100 group-hover/section:mb-2",
                        "transition-all duration-300",
                        "delay-[4000ms] group-hover/section:delay-[4000ms]"
                      )}>
                        <div className="relative flex-1">
                          <input
                            type="text"
                            value={searchState.searchTerm}
                            placeholder={item.searchTerm}
                            onChange={(e) => {
                              const term = e.target.value;
                              setSearchStates(prev => ({
                                ...prev,
                                [searchKey]: {
                                  ...prev[searchKey],
                                  searchTerm: term,
                                },
                              }));
                              debouncedSearch(searchKey, term, searchState.searchType || item.searchType);
                            }}
                            className={cn(
                              'w-full pl-10 pr-4 py-1.5 rounded-lg text-sm',
                              'bg-charcoal-800 border border-charcoal-700',
                              'text-white placeholder-charcoal-400',
                              'focus:outline-none focus:ring-2 focus:ring-electric-blue/50'
                            )}
                          />
                          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-charcoal-400" />
                        </div>

                        {/* Search Type Dropdown */}
                        <select
                          value={searchState.searchType || item.searchType}
                          onChange={(e) => {
                            const type = e.target.value;
                            setSearchStates(prev => ({
                              ...prev,
                              [searchKey]: {
                                ...prev[searchKey],
                                searchType: type,
                              },
                            }));
                            if (searchState.searchTerm) {
                              debouncedSearch(searchKey, searchState.searchTerm, type);
                            }
                          }}
                          className={cn(
                            "px-2 py-1.5 rounded-lg text-sm",
                            "bg-charcoal-800 border border-charcoal-700",
                            "text-charcoal-300 hover:text-white",
                            "focus:outline-none focus:ring-2 focus:ring-electric-blue/50",
                            "transition-colors duration-200"
                          )}
                        >
                          <option value="course">Course</option>
                          <option value="lab">Lab</option>
                          <option value="assessment">Assessment</option>
                          <option value="scholar">Scholar</option>
                          <option value="openalex">OpenAlex</option>
                          <option value="pubmed">PubMed</option>
                          <option value="wikipedia">Wikipedia</option>
                          <option value="google-books">Books</option>
                          <option value="youtube">YouTube</option>
                          <option value="website">Website</option>
                        </select>

                        {searchState.results.length > 0 && (
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => navigateResults(searchKey, 'prev')}
                              className="p-2 text-charcoal-400 hover:text-white"
                            >
                              <ArrowLeft className="w-4 h-4" />
                            </button>
                            <span className="text-sm text-charcoal-400">
                              {searchState.currentIndex + 1} / {searchState.results.length}
                            </span>
                            <button
                              onClick={() => navigateResults(searchKey, 'next')}
                              className="p-2 text-charcoal-400 hover:text-white"
                            >
                              <ArrowRight className="w-4 h-4" />
                            </button>
                          </div>
                        )}
                      </div>

                      {/* Content Display */}
                      {searchState.isLoading ? (
                        <div className="flex items-center justify-center p-4">
                          <Loader2 className="w-6 h-6 text-electric-blue animate-spin" />
                        </div>
                      ) : searchState.error ? (
                        <div className="text-red-400 text-sm p-4 text-center">
                          {searchState.error}
                        </div>
                      ) : selectedCourse ? (
                        <a
                          href={selectedCourse.url}
                          key={selectedCourse.id}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block rounded-lg overflow-hidden hover:bg-charcoal-800/30 transition-all duration-200 -mt-2"
                        >
                          <div className="flex items-start gap-3 p-3 pt-2 relative group">
                            {selectedCourse.image && (
                              <img
                                src={selectedCourse.image}
                                alt={selectedCourse.title}
                                className="w-36 h-24 object-cover rounded"
                              />
                            )}
                            <div className="flex-1 min-w-0">
                              <h4 className="font-medium text-white mb-1 truncate">
                                {selectedCourse.title}
                              </h4>
                              <div 
                                className="text-sm text-charcoal-300 line-clamp-2"
                                dangerouslySetInnerHTML={{ __html: selectedCourse.description }}
                              />
                             {selectedCourse.lecturesMeta?.numberSelectedLectures > 0 && (
                               <div className="mt-2 flex items-center gap-2">
                                 <span className="text-xs px-2 py-0.5 rounded-full bg-electric-blue/10 text-electric-blue border border-electric-blue/20">
                                  {selectedCourse.lecturesMeta.numberSelectedLectures} Selected Lectures
                                 </span>
                                 <span className="text-xs text-charcoal-400">
                                  from {selectedCourse.lecturesMeta.totalLectures} total lectures
                                 </span>
                               </div>
                             )}
                            </div>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                removeCourse(sectionIndex, itemIndex);
                              }}
                              className={cn(
                                "absolute right-2 top-1/2 -translate-y-1/2",
                                "p-1.5 rounded-full",
                                "bg-charcoal-800/90 opacity-0 group-hover:opacity-100",
                                "text-charcoal-400 hover:text-red-400",
                                "transition-all duration-200"
                              )}
                            >
                              <X className="w-4 h-4" />
                            </button>
                          </div>
                        </a>
                      ) : currentResult ? (
                        <a
                          href={currentResult.url}
                          key={currentResult.id}
                          onClick={(e) => {
                            e.preventDefault();
                            // Click selection is still supported if needed
                            handleCourseSelect(sectionIndex, itemIndex, currentResult);
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block rounded-lg overflow-hidden hover:bg-charcoal-800/30 transition-all duration-200"
                        >
                          <div className="flex items-start gap-4 p-4 pt-2 relative group">
                            {currentResult.image_480x270 && (
                              <img
                                src={currentResult.image_480x270}
                                alt={currentResult.title}
                                className="w-36 h-24 object-cover rounded"
                              />
                            )}
                            <div className="flex-1 min-w-0">
                              <h4 className="font-medium text-white mb-1 truncate">
                                {currentResult.title}
                              </h4>
                              <div 
                                className="text-sm text-charcoal-300 line-clamp-2"
                                dangerouslySetInnerHTML={{ __html: currentResult.headline }}
                              />
                            </div>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                removeCourse(sectionIndex, itemIndex);
                              }}
                              className={cn(
                                "absolute right-2 top-1/2 -translate-y-1/2",
                                "p-1.5 rounded-full",
                                "bg-charcoal-800/90 opacity-0 group-hover:opacity-100",
                                "text-charcoal-400 hover:text-red-400",
                                "transition-all duration-200"
                              )}
                            >
                              <X className="w-4 h-4" />
                            </button>
                          </div>
                        </a>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ))}
      {error && <div className="error-message text-red-500">{error}</div>}
      </div>
    </div>
  );
}