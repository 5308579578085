// @ts-nocheck
import React, { useEffect } from 'react';
import { PenSquare, Sparkles } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useCanvasStore } from '@/lib/stores/canvasStore';

interface EditOverlayProps {
  show: boolean;
  onNewPath: () => void;
  isTextareaActive: boolean;
  onVisibilityChange?: (visible: boolean) => void;
}

export const EditOverlay: React.FC<EditOverlayProps> = ({ 
  show, 
  onNewPath, 
  isTextareaActive,
  onVisibilityChange 
}) => {
  const { leftPanelWidth } = useCanvasStore();
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false);

  // Update parent component's state when overlay visibility changes
  useEffect(() => {
    if (show && isTextareaActive) {
      setIsOverlayVisible(true);
      onVisibilityChange?.(true);
      document.body.style.overflow = 'hidden';
    } else {
      setIsOverlayVisible(false);
      onVisibilityChange?.(false);
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
      onVisibilityChange?.(false);
    };
  }, [show, isTextareaActive, onVisibilityChange]);

  if (!show || !isTextareaActive) return null;

  return (
    <div className={cn(
      "absolute inset-0 z-20",
      "bg-charcoal-950/80 backdrop-blur-sm",
      "flex items-center justify-center",
      "animate-in fade-in duration-200"
    )}
    style={{ 
      width: `${100}%`
    }}>
      <div className={cn(
        "max-w-md w-full mx-4",
        "bg-charcoal-900/90 rounded-xl",
        "border border-charcoal-800/50",
        "p-8 text-center space-y-6",
        "animate-in slide-in-from-bottom-4 duration-300"
      )}>
        <div className="space-y-2">
          <div className="inline-flex items-center gap-2 text-electric-blue">
            <Sparkles className="w-5 h-5" />
            <span className={cn(
              "text-lg font-bold",
              "bg-gradient-to-r from-purple-400 via-electric-blue to-purple-400",
              "bg-[length:200%_100%] bg-clip-text text-transparent",
              "animate-shimmer"
            )}>
              Editing Learning Path
            </span>
          </div>
          <p className="text-charcoal-300">
            Ask Phil what you would like to change!
          </p>
        </div>

        <div className="pt-2">
          <button
            onClick={onNewPath}
            className={cn(
              "group relative w-full",
              "px-4 py-3 rounded-lg",
              "bg-gradient-to-r from-electric-blue/10 to-blue-500/10",
              "border border-electric-blue/20 hover:border-electric-blue/40",
              "text-electric-blue font-medium",
              "transition-all duration-300 ease-out",
              "overflow-hidden"
            )}
          >
            {/* Animated background */}
            <div className={cn(
              "absolute inset-0 opacity-0 group-hover:opacity-100",
              "bg-gradient-to-r from-electric-blue/5 to-blue-500/5",
              "transition-opacity duration-300"
            )} />

            {/* Button content */}
            <div className="relative flex items-center justify-center gap-2">
              <PenSquare className="w-5 h-5" />
              Create New Path
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};