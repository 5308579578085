// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react';
import { useCanvasStore } from '@/lib/stores/canvasStore';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { cn } from '@/lib/utils';

interface ResizablePanelProps {
  leftPanel: React.ReactNode;
  rightPanel: React.ReactNode;
  initialLeftWidth?: number;
  minLeftWidth?: number;
  maxLeftWidth?: number;
}

export function ResizablePanel({
  leftPanel,
  rightPanel,
  initialLeftWidth = 40,
  minLeftWidth = 30,
  maxLeftWidth = 70,
}: ResizablePanelProps) {
  const { setLeftPanelWidth } = useCanvasStore();
  const { activeChat, chats } = useChatListStore();
  const [leftWidth, setLeftWidth] = useState(initialLeftWidth);
  const [isDragging, setIsDragging] = useState(false);
  const chat = activeChat ? chats.find(c => c.id === activeChat) : null;
  const { type } = useCanvasStore();
  const activeCanvasType = chat?.canvasType || type;

  // Initialize panel width in store
  useEffect(() => {
    setLeftPanelWidth(initialLeftWidth);
  }, [initialLeftWidth, setLeftPanelWidth, activeCanvasType, chat?.status]);

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) return;

      const container = e.currentTarget as HTMLDivElement;
      const containerRect = container.getBoundingClientRect();
      const newLeftWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;
      const clampedWidth = Math.min(Math.max(newLeftWidth, minLeftWidth), maxLeftWidth);
      
      setLeftWidth(clampedWidth);
      setLeftPanelWidth(clampedWidth);
    },
    [isDragging, minLeftWidth, maxLeftWidth, setLeftPanelWidth]
  );

  return (
    <div
      className="flex h-full"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div style={{ width: `${leftWidth}%` }} className="h-full">
        {leftPanel}
      </div>

      <div
        className={cn(
          "w-1 h-full bg-charcoal-800 hover:bg-electric-blue/50 cursor-col-resize relative group",
          isDragging && "bg-electric-blue"
        )}
        onMouseDown={handleMouseDown}
      >
        <div className="absolute inset-y-0 -left-2 right-2 group-hover:bg-electric-blue/10" />
      </div>

      <div style={{ width: `${100 - leftWidth}%` }} className="h-full">
        {rightPanel}
      </div>
    </div>
  );
}