// @ts-nocheck
import React, { useState } from 'react';
import { Archive, Trash2, Undo2 } from 'lucide-react';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { useAuthStore } from '@/lib/store';
import { updateChatData } from '@/lib/services/chat/persistence';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';

export function ArchivedChatsSettings() {
  const { chats, updateChat } = useChatListStore();
  const user = useAuthStore((state) => state.user);
  const [animatingChats, setAnimatingChats] = useState<Set<string>>(new Set());
  const [deletingChats, setDeletingChats] = useState<Set<string>>(new Set());
  const [restoringChats, setRestoringChats] = useState<Set<string>>(new Set());

  // Get only archived chats, sorted by updated date
  const archivedChats = chats
    .filter(chat => chat.archived && !chat.deleted)
    .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());

  const handleRestore = async (chatId: string) => {
    if (!user) return;
    setRestoringChats(prev => new Set(prev).add(chatId));

    try {
      // Start animation and update simultaneously
      await Promise.all([
        new Promise(resolve => setTimeout(resolve, 1000)), // Longer animation duration
        updateChatData(user.uid, chatId, { archived: false })
          .then(() => updateChat(chatId, { archived: false }))
      ]);
    } catch (error) {
      console.error('Failed to restore chat:', error);
    } finally {
      setRestoringChats(prev => {
        const next = new Set(prev);
        next.delete(chatId);
        return next;
      });
    }
  };

  const handleDelete = async (chatId: string) => {
    if (!user) return;
    setDeletingChats(prev => new Set(prev).add(chatId));

    try {
      // Start animation and update simultaneously
      await Promise.all([
        new Promise(resolve => setTimeout(resolve, 1000)), // Longer animation duration
        updateChatData(user.uid, chatId, { deleted: true })
          .then(() => updateChat(chatId, { deleted: true }))
      ]);
    } catch (error) {
      console.error('Failed to delete chat:', error);
    } finally {
      setDeletingChats(prev => {
        const next = new Set(prev);
        next.delete(chatId);
        return next;
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <Archive className="w-6 h-6 text-electric-blue" />
        <h3 className="text-lg font-medium text-white">Archived Chats</h3>
      </div>

      {archivedChats.length === 0 ? (
        <div className="text-center p-8 bg-charcoal-800/30 rounded-lg border border-charcoal-700/30">
          <p className="text-charcoal-400">No archived chats</p>
        </div>
      ) : (
        <div className="space-y-3">
          <AnimatePresence mode="popLayout">
          {archivedChats.map((chat) => (
            <motion.div
              layout="position"
              key={chat.id}
              initial={{ opacity: 1, scale: 1, y: 0, rotate: 0 }}
              animate={{
                scale: deletingChats.has(chat.id) ? [1, 1.1, 0.9] : 1,
                x: deletingChats.has(chat.id) ? [0, 20, 1000] : 
                   restoringChats.has(chat.id) ? [0, -20, -1000] : 0,
                opacity: (deletingChats.has(chat.id) || restoringChats.has(chat.id)) ? 0 : 1,
                transition: {
                  duration: 1,
                  ease: "easeInOut",
                  delay: 0.1 * archivedChats.indexOf(chat)
                }
              }}
              className={cn(
                "group relative p-4 rounded-lg transform transition-all duration-300",
                "bg-charcoal-800/30 border border-charcoal-700/30",
                "hover:border-electric-blue/20",
                "transition-all duration-200",
                (deletingChats.has(chat.id) || restoringChats.has(chat.id)) && "pointer-events-none"
              )}
            >
              {/* Background effect for delete animation */}
              {deletingChats.has(chat.id) && (
                <motion.div
                  className="absolute inset-0 rounded-lg"
                  initial={{ opacity: 0 }}
                  animate={{ 
                    opacity: [0, 1, 0],
                    backgroundColor: ["rgba(239, 68, 68, 0.1)", "rgba(239, 68, 68, 0.3)", "rgba(239, 68, 68, 0)"],
                    x: [0, 10, 1000]
                  }}
                  transition={{ duration: 1, ease: "easeInOut" }}
                />
              )}
              
              {/* Background effect for restore animation */}
              {restoringChats.has(chat.id) && (
                <motion.div
                  className="absolute inset-0 rounded-lg"
                  initial={{ opacity: 0 }}
                  animate={{ 
                    opacity: [0, 1, 0],
                    backgroundColor: ["rgba(34, 197, 94, 0.1)", "rgba(34, 197, 94, 0.3)", "rgba(34, 197, 94, 0)"],
                    x: [0, -10, -3000]
                  }}
                  transition={{ duration: 1.3, ease: "easeInOut" }}
                />
              )}

              <div className="flex items-start justify-between">
                <div>
                  <h4 className="text-sm font-medium text-white">{chat.title}</h4>
                  <p className="text-xs text-charcoal-400">
                    Archived on {format(chat.updatedAt, 'MMM d, yyyy')}
                  </p>
                  <p className="text-xs text-charcoal-400 mt-1">
                    {chat.messages.length} messages
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleRestore(chat.id)}
                    className={cn(
                      "p-2 rounded-lg relative",
                      "text-charcoal-400 hover:text-electric-blue",
                      "hover:bg-electric-blue/10",
                      "transition-colors duration-200"
                    )}
                    title="Restore chat"
                  >
                    <span className="absolute inset-0 bg-electric-blue/5 rounded-lg scale-0 group-hover:scale-100 transition-transform duration-200" />
                    <Undo2 className="w-4 h-4" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(chat.id);
                    }}
                    className={cn(
                      "p-2 rounded-lg relative",
                      "text-charcoal-400 hover:text-red-400",
                      "hover:bg-red-400/10",
                      "transition-colors duration-200"
                    )}
                    title="Permanently delete"
                  >
                    <span className="absolute inset-0 bg-red-400/5 rounded-lg scale-0 group-hover:scale-100 transition-transform duration-200" />
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
}