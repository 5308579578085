// @ts-nocheck
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UdemyState {
  domain: string | null;
  token: string | null;
  accountId: string | null;
  isConnected: boolean;
  validateConnection: (domain: string, token: string) => Promise<void>;
  setCredentials: (domain: string, token: string, accountId: string) => void;
  clearCredentials: () => void;
}

export const useUdemyStore = create<UdemyState>()(
  persist(
    (set) => ({
      domain: null,
      token: null,
      accountId: null,
      isConnected: false,
      setDomain: (domain: string | null) => set({ 
        domain,
        isConnected: false 
      }),
      setToken: (token: string | null) => set({ 
        token,
        isConnected: false 
      }),
      validateConnection: async (domain: string, token: string) => {
        try {
          // Update store immediately when validation starts
          set({ 
            domain,
            token,
            accountId: null,
            isConnected: false 
          });

          const response = await fetch('https://app.philomathia.ai/udemy/getEnvironmentStatus', {
            method: 'GET',
            headers: {
              'domain': domain,
              'Authorization': token
            }
          });

          const data = await response.json();
          if (!response.ok || !data.success) {
            set({ 
              isConnected: false,
              accountId: null
            });
            return;
          }

          set({ 
            domain,
            token,
            accountId: data.accountID,
            isConnected: true 
          });
        } catch (error) {
          set({ 
            isConnected: false,
            accountId: null
          });
        }
      },
      setCredentials: (domain, token, accountId) => set({
        domain,
        token,
        accountId,
        isConnected: true
      }),
      clearCredentials: () => set({
        domain: null,
        token: null,
        accountId: null,
        isConnected: false
      })
    }),
    {
      name: 'udemy-storage'
    }
  )
);