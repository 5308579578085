// @ts-nocheck
import OpenAI from 'openai';
import { AIMessage, AIResponse } from '../types';
import { handleAIError } from '../error';
import { withRetry } from '../retry';
import { getOfflineResponse } from '../offline';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export async function chatWithOpenAI(
  messages: AIMessage[],
  model: 'gpt-4o-mini' | 'gpt-4o',
  onChunk?: (chunk: string) => void
): Promise<AIResponse> {
  const formattedMessages = messages.map(msg => ({
    role: msg.role,
    content: msg.content,
  }));

  try {
    if (onChunk) {
      return await withRetry(async () => {
        const stream = await openai.chat.completions.create({
          model,
          messages: formattedMessages,
          temperature: 0.7,
          stream: true,
        });

        let fullContent = '';
        for await (const chunk of stream) {
          const content = chunk.choices[0]?.delta?.content || '';
          if (content) {
            fullContent += content;
            onChunk(content);
          }
        }

        return {
          content: fullContent,
          model,
          suggestions: []
        };
      });
    }

    return await withRetry(async () => {
      const response = await openai.chat.completions.create({
        model,
        messages: formattedMessages,
        response_format: { type: "json_object" },
        temperature: 0.7,
      });

      return {
        content: response.choices[0]?.message?.content || '',
        model,
        suggestions: []
      };
    });
  } catch (error: unknown) {
    if (error instanceof Error && 
        (error.message.includes('network') || error.message.includes('connection'))) {
      return getOfflineResponse(messages);
    }
    
    throw handleAIError(error);
  }
}