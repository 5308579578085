// @ts-nocheck
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { Chat } from '@/lib/stores/chatListStore';
import { getDoc, getDocs, collection, serverTimestamp, Timestamp } from 'firebase/firestore';

export async function loadUserChats(userId: string): Promise<Chat[]> {
  try {
    if (!userId) throw new Error('User ID is required');
    
    const chatsRef = collection(db, `users/${userId}/chats`); 
    const querySnapshot = await getDocs(chatsRef);
    
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      // Skip deleted chats during load
      if (data.deleted) return null;
      
      return {
        id: doc.id,
        title: data.title,
        archived: data.archived || false,
        deleted: data.deleted || false,
        messages: data.messages?.map((msg: any) => ({
          ...msg,
          timestamp: new Date(msg.timestamp)
        })) || [],
        requirements: data.requirements?.map((req: any) => ({
          ...req,
          timestamp: new Date(req.timestamp)
        })) || [],
        skillTree: data.skillTree || null,
        learningPaths: data.learningPaths || null,
        canvasType: data.canvasType || 'learning-program',
        status: data.status || 'active',
        createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(data.createdAt),
        updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date(data.updatedAt)
      };
    }).filter(Boolean);
  } catch (error) {
    console.error('Error loading user chats:', error);
    throw error;
  }
}

// Validate chat data before saving
function validateChatData(data: any) {
  if (!data.id) throw new Error('Chat ID is required');
  if (!data.title) throw new Error('Chat title is required');
  if (!Array.isArray(data.messages)) throw new Error('Messages must be an array');
  if (!Array.isArray(data.requirements)) throw new Error('Requirements must be an array');
}

export async function saveChat(userId: string, chat: Chat) {
  try {
    if (!userId) throw new Error('User ID is required');
    // Only save activated chats
    if (chat.status === 'initializing') {
      console.log('Skipping save for initializing chat:', chat.id);
      return;
    }
    
    validateChatData(chat);
    
    const chatRef = doc(db, `users/${userId}/chats/${chat.id}`);

    // Prepare data for Firestore
    const chatData = {
      id: chat.id,
      title: chat.title,
      canvasType: chat.canvasType,
      archived: chat.archived || false,
      deleted: chat.deleted || false,
      status: chat.status || 'active',
      messages: chat.messages?.map(msg => ({
        id: msg.id,
        content: msg.content,
        role: msg.role,
        timestamp: msg.timestamp?.toISOString() || new Date().toISOString(),
        suggestions: msg.suggestions || [],
        error: msg.error || false
      })) || [],
      requirements: chat.requirements?.map(req => ({
        id: req.id,
        category: req.category,
        content: req.content,
        timestamp: req.timestamp?.toISOString() || new Date().toISOString(),
        document: req.document || null
      })) || [],
      skillTree: chat.skillTree || null,
      learningPaths: chat.learningPaths || null,
      createdAt: chat.createdAt?.toISOString() || new Date().toISOString(),
      updatedAt: serverTimestamp()
    };

    const docSnap = await getDoc(chatRef);
    
    if (!docSnap.exists()) {
      console.log('Creating new chat document:', chat.id);
      try {
        await setDoc(chatRef, chatData);
      } catch (error) {
        console.error('Error creating chat document:', error);
        throw new Error('Failed to create chat document');
      }
    }
  } catch (error) {
    console.error('Error saving chat:', error);
    throw error;
  }
}

export async function updateChatData(userId: string, chatId: string, data: Partial<Chat>) {
  try {
    if (!userId) throw new Error('User ID is required');
    if (!chatId) throw new Error('Chat ID is required');

    // Get current chat data to check status
    const chatRef = doc(db, `users/${userId}/chats/${chatId}`);
    const docSnap = await getDoc(chatRef);
    const currentChat = docSnap.exists() ? docSnap.data() : null;

    // Skip updates for initializing chats unless explicitly activating
    if (currentChat?.status === 'initializing' && data.status !== 'active') {
      console.log('Skipping update for initializing chat:', chatId);
      return;
    }
    
    // Convert dates to ISO strings
    const updateData = {
      ...(data.title && { title: data.title }),
      ...(data.archived !== undefined && { archived: data.archived }),
      ...(data.deleted !== undefined && { deleted: data.deleted }),
      ...(data.canvasType !== undefined && { canvasType: data.canvasType }),
      ...(data.status && { status: data.status }),
      ...(data.messages && {
        messages: data.messages.map(msg => ({
          id: msg.id,
          content: msg.content,
          role: msg.role,
          timestamp: msg.timestamp?.toISOString() || new Date().toISOString(),
          suggestions: msg.suggestions || [],
          error: msg.error || false
        }))
      }),
      ...(data.requirements && {
        requirements: data.requirements.map(req => ({
          id: req.id,
          category: req.category,
          content: req.content,
          timestamp: req.timestamp?.toISOString() || new Date().toISOString(),
          document: req.document || null
        }))
      }),
      ...(data.skillTree && { skillTree: data.skillTree }),
      ...(data.learningPaths && { learningPaths: data.learningPaths }),
      updatedAt: serverTimestamp()
    };
    
    // Check if document exists first
    if (!docSnap.exists()) {
      console.log('Creating new chat document on update:', chatId);
      const newChatData = {
        id: chatId, 
        title: data.title || 'New Chat',
        canvasType: data.canvasType || 'learning-program',
        messages: updateData.messages || [],
        status: data.status || 'initializing',
        requirements: updateData.requirements || [],
        archived: data.archived || false,
        skillTree: updateData.skillTree || null,
        learningPaths: updateData.learningPaths || null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      try {
        await setDoc(chatRef, newChatData);
      } catch (error) {
        console.error('Error creating chat document on update:', error);
        throw new Error('Failed to create chat document');
      }
      return;
    }

    // Update existing document
    try {
      await updateDoc(chatRef, updateData);
    } catch (error) {
      console.error('Error updating chat document:', error);
      throw new Error('Failed to update chat document');
    }
  } catch (error) {
    console.error('Error updating chat:', error);
    throw error;
  }
}