// @ts-nocheck
import { jsPDF } from 'jspdf';

const PHILOMATH_DESCRIPTION = `Philomath is an advanced AI-powered learning assistant designed to create personalized, comprehensive learning paths. By leveraging cutting-edge artificial intelligence and a deep understanding of educational principles, Philomath helps organizations and individuals design effective learning programs tailored to their specific needs and goals.

Key Features:
• Intelligent Learning Path Design
• Personalized Content Recommendations
• Comprehensive Skill Mapping
• Adaptive Learning Strategies

Created by industry experts in education and AI technology, Philomath represents the future of professional development and learning program design.`;

const ACCENT_COLOR = [0, 191, 255];
const TEXT_DARK = [31, 41, 55];
const TEXT_MEDIUM = [107, 114, 128];
const BG_LIGHT = [249, 250, 251];
const BORDER_GRAY = [229, 231, 235];
const BG_COVER = [20, 24, 33];

const CARD_STYLES = {
  padding: 5,
  imageHeight: 30,
  imageWidth: 53.33,
  borderRadius: 3,
};

const addClickableArea = (doc: any, x: number, y: number, width: number, height: number, url: string) => {
  doc.link(x, y, width, height, { url });
};

export async function exportToPdf(learningPath: any) {
  const doc = new jsPDF();
  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  
  const addWrappedText = (text: string, y: number, fontSize: number = 12, color = TEXT_DARK, isBold = false, maxWidth?: number) => {
    doc.setFont('helvetica', isBold ? 'bold' : 'normal');
    doc.setFontSize(fontSize);
    doc.setTextColor(...color);
    const lines = doc.splitTextToSize(text, maxWidth || (pageWidth - 2 * margin));
    doc.text(lines, margin, y);
    return y + (lines.length * fontSize * 0.352778);
  };

  const drawCard = (x: number, y: number, width: number, height: number) => {
    doc.setFillColor(...BG_LIGHT);
    doc.roundedRect(x, y, width, height, CARD_STYLES.borderRadius, CARD_STYLES.borderRadius, 'F');
    doc.setDrawColor(...BORDER_GRAY);
    doc.roundedRect(x, y, width, height, CARD_STYLES.borderRadius, CARD_STYLES.borderRadius, 'S');
  };

  // Cover Page
  doc.setFillColor(...BG_COVER);
  doc.rect(0, 0, pageWidth, pageHeight, 'F');

  // Use a semi-transparent PNG (80% opacity) to achieve the desired effect
  const headerImageURL = 'https://static.vecteezy.com/system/resources/previews/012/255/343/non_2x/dynamic-abstract-liquid-flow-particles-background-shining-abstract-particle-flow-background-vector.jpg'; 
  const headerImageWidth = pageWidth; 
  const headerImageHeight = (headerImageWidth * 9) / 16; 
  const headerImageX = (pageWidth - headerImageWidth) / 2;
  const headerImageY = pageHeight * 0.1;
  
  try {
    doc.addImage(headerImageURL, 'JPEG', headerImageX, headerImageY, headerImageWidth, headerImageHeight);
  } catch (error) {
    console.warn('Failed to add header image:', error);
  }

  doc.setFont('helvetica', 'bold');
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(26);

  let yPos = headerImageY + headerImageHeight + 20;
  const titleLines = doc.splitTextToSize(learningPath.name, pageWidth - 2 * margin);
  titleLines.forEach((line: string, i: number) => {
    const textWidth = doc.getTextWidth(line);
    doc.text(line, (pageWidth - textWidth) / 2, yPos + (i * 10));
  });
  yPos += titleLines.length * 10;

  doc.setFont('helvetica', 'normal');
  doc.setFontSize(12);
  doc.setTextColor(200, 200, 200);
  const descLines = doc.splitTextToSize(learningPath.description, pageWidth * 0.6);
  descLines.forEach((line: string, i: number) => {
    const textWidth = doc.getTextWidth(line);
    doc.text(line, (pageWidth - textWidth) / 2, yPos + 15 + (i * 7));
  });

  doc.setFontSize(10);
  doc.setTextColor(...ACCENT_COLOR);
  const footerText = 'Generated by Philomath AI';
  const footerWidth = doc.getTextWidth(footerText);
  doc.text(footerText, pageWidth - margin - footerWidth, pageHeight - 20);

  doc.setFontSize(10);
  doc.setTextColor(255, 255, 255);
  doc.text(new Date().toLocaleDateString(), margin, pageHeight - 20);

  // Content Pages
  doc.addPage();
  doc.setFillColor(255, 255, 255);
  doc.rect(0, 0, pageWidth, pageHeight, 'F');
  yPos = 20;

  learningPath.sections.forEach((section: any, index: number) => {
    if (yPos > pageHeight - 60) {
      doc.addPage();
      yPos = 20;
    }

    doc.setDrawColor(...ACCENT_COLOR);
    doc.setLineWidth(0.5);
    doc.line(margin, yPos, pageWidth - margin, yPos);
    yPos += 10;
    yPos = addWrappedText(`${index + 1}. ${section.title}`, yPos, 14, TEXT_DARK, true);

    yPos += 5;
    yPos = addWrappedText(section.description, yPos, 12, TEXT_MEDIUM);

    section.items.forEach((item: any) => {
      if (yPos > pageHeight - 60) {
        doc.addPage();
        yPos = 20;
      }
      yPos += 8;

      if (item.title) {
        const cardHeight = 40;
        drawCard(margin, yPos, pageWidth - (2 * margin), cardHeight);

        if (item.url) {
          addClickableArea(doc, margin, yPos, pageWidth - (2 * margin), cardHeight, item.url);
        }

        if (item.image) {
          try {
            doc.addImage(
              item.image,
              'JPEG',
              margin + CARD_STYLES.padding,
              yPos + CARD_STYLES.padding,
              CARD_STYLES.imageWidth,
              CARD_STYLES.imageHeight
            );
          } catch (error) {
            console.warn('Failed to add image:', error);
          }
        }

        const contentX = margin + CARD_STYLES.padding + (item.image ? CARD_STYLES.imageWidth + 10 : 0);
        const contentWidth = pageWidth - (2 * margin) - (contentX - margin);

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.setTextColor(...TEXT_DARK);
        const titleLines = doc.splitTextToSize(item.title, contentWidth - CARD_STYLES.padding * 2);
        doc.text(titleLines, contentX, yPos + 12);

        if (item.description) {
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(10);
          doc.setTextColor(...TEXT_MEDIUM);
          const descLines = doc.splitTextToSize(item.description, contentWidth - CARD_STYLES.padding * 2);
          doc.text(descLines, contentX, yPos + 22);
        }
        
        yPos += cardHeight;
      }
    });
  });

  // End Page
  doc.addPage();
  doc.setFillColor(...BG_COVER);
  doc.rect(0, 0, pageWidth, pageHeight, 'F');

  doc.setFontSize(28);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(255, 255, 255);
  let endY = pageHeight * 0.3;
  doc.text('Philomath', margin, endY);

  doc.setFontSize(16);
  doc.setFont('helvetica', 'normal');
  doc.setTextColor(...ACCENT_COLOR);
  endY += 10;
  doc.text('AI Learning Assistant', margin, endY);

  doc.setFontSize(12);
  doc.setFont('helvetica', 'normal');
  doc.setTextColor(255, 255, 255);
  endY += 20;
  const philomathDescLines = doc.splitTextToSize(PHILOMATH_DESCRIPTION, pageWidth - (2 * margin));
  doc.text(philomathDescLines, margin, endY);

  const filename = `${learningPath.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_learning_path.pdf`;
  doc.save(filename);

  return { success: true };
}
