// @ts-nocheck
import { ProcessedFile, FileProcessor } from '../types';
import { generateId } from '@/lib/utils';
import { Timestamp } from 'firebase/firestore';

export const processTextFile: FileProcessor = async (file: File): Promise<ProcessedFile> => {
  try {
    const text = await file.text();
    const wordCount = text.trim().split(/\s+/).length;

    return {
      id: generateId(),
      name: file.name,
      type: 'txt',
      content: text,
      metadata: {
        size: file.size,
        lastModified: file.lastModified,
        wordCount,
      },
      createdAt: Timestamp.now(),
    };
  } catch (error) {
    console.error('Error processing text file:', error);
    throw new Error('Failed to process text file');
  }
};