// @ts-nocheck
import { AIMessage, AIResponse, AIModel } from '../types';
import { chatWithOpenAI } from './openai';
import { chatWithGemini } from './gemini';
import { chatWithAnthropic } from './anthropic';

export async function getAIResponse(
  messages: AIMessage[],
  model: 'gpt-4o',
  onChunk?: (chunk: string) => void
): Promise<AIResponse> {
  try {
    switch (model) {
      case 'gpt-4o-mini':
      case 'gpt-4o':
        return chatWithOpenAI(messages, model, onChunk);
      case 'gemini-pro':
        return chatWithGemini(messages);
      case 'claude-3':
        return chatWithAnthropic(messages);
      default:
        throw new Error(`Unsupported model: ${model}`);
    }
  } catch (error) {
    console.error('AI provider error:', error);
    throw error;
  }
}

export type Provider = 'openai' | 'google' | 'anthropic';