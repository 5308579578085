// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { cn } from '@/lib/utils';
import { Modal } from '../ui/Modal';
import { Loader2, AlertCircle, CheckCircle2, HelpCircle, Link } from 'lucide-react';
import { useUdemyStore } from '@/lib/stores/udemyStore';

interface UdemyConnectionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function UdemyConnectionModal({ isOpen, onClose }: UdemyConnectionModalProps) {
  const [domain, setDomain] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState<'idle' | 'connecting' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const { setCredentials, domain: savedDomain, token: savedToken, isConnected, setDomain: updateStoreDomain, setToken: updateStoreToken } = useUdemyStore();

  // Load saved connection details on mount
  useEffect(() => {
    if (savedDomain && savedToken) {
      setDomain(savedDomain);
      setAuthToken(savedToken);
      setConnectionStatus(isConnected ? 'success' : 'error');
    }
  }, [savedDomain, savedToken, isConnected]);

  // Debounced validation function
  const debouncedValidation = useCallback(
    debounce(async (domainValue: string, tokenValue: string) => {
      if (domainValue.length > 0 && tokenValue.length >= 35) {
        await validateConnection(domainValue, tokenValue);
      }
    }, 1000),
    []
  );

  // Handle domain input with auto-formatting
  const handleDomainChange = (value: string) => {
    // Remove .udemy.com suffix if present
    const cleanDomain = value.replace(/\.udemy\.com$/, '');
    setDomain(cleanDomain);
    updateStoreDomain(cleanDomain);
    setConnectionStatus('idle');
    
    if (cleanDomain.length > 0 && authToken.length >= 35) {
      debouncedValidation(cleanDomain, authToken);
    }
  };

  // Handle auth token input
  const handleAuthTokenChange = (value: string) => {
    setAuthToken(value);
    updateStoreToken(value);
    setConnectionStatus('idle');
    
    if (value.length >= 35 && domain.length > 0) {
      debouncedValidation(domain, value);
    }
  };

  const validateConnection = async (domainValue: string, tokenValue: string) => {
    setIsConnecting(true);
    setConnectionStatus('connecting');
    setErrorMessage('');

    try {
      const response = await fetch('https://app.philomathia.ai/udemy/getEnvironmentStatus', {
        method: 'GET',
        headers: {
          'domain': domainValue,
          'Authorization': tokenValue
        }
      });

      const data = await response.json();

      if (data.success) {
        setCredentials(domainValue, tokenValue, data.accountID);
        setConnectionStatus('success');
      } else {
        setConnectionStatus('error');
        setErrorMessage('Failed to validate credentials');
      }
    } catch (error) {
      setConnectionStatus('error');
      setErrorMessage('Connection failed. Please check your credentials and try again.');
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Connect Udemy Business"
    >
      <div className="space-y-6">
        {/* Connection Form */}
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-white">
              Domain
              <button
                type="button"
                className="text-charcoal-400 hover:text-white"
                title="Your Udemy Business domain"
              >
                <HelpCircle className="w-4 h-4" />
              </button>
            </label>
            <div className="relative">
              <input
                type="text"
                value={domain}
                onChange={(e) => handleDomainChange(e.target.value)}
                placeholder="your-company"
                className={cn(
                  "w-full px-3 py-2 rounded-lg",
                  "bg-charcoal-700 border border-charcoal-600",
                  "text-white placeholder-charcoal-400",
                  "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                )}
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-charcoal-400">
                .udemy.com
              </span>
            </div>
          </div>

          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-white">
              Auth Token
              <button
                type="button"
                className="text-charcoal-400 hover:text-white"
                title="Your Udemy Business API token"
              >
                <HelpCircle className="w-4 h-4" />
              </button>
            </label>
            <input
              type="password"
              value={authToken}
              onChange={(e) => handleAuthTokenChange(e.target.value)}
              minLength={35}
              maxLength={50}
              placeholder="Enter your auth token"
              className={cn(
                "w-full px-3 py-2 rounded-lg",
                "bg-charcoal-700 border border-charcoal-600",
                "text-white placeholder-charcoal-400",
                "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
              )}
            />
          </div>
        </div>

        {/* Status Messages */}
        {connectionStatus === 'connecting' && (
          <div className="flex items-center gap-2 text-sm text-electric-blue">
            <Loader2 className="w-4 h-4 animate-spin" />
            Validating connection...
          </div>
        )}

        {connectionStatus === 'success' && (
          <div className="flex items-center gap-2 text-sm text-green-400">
            <CheckCircle2 className="w-4 h-4" />
            Connected successfully!
          </div>
        )}

        {connectionStatus === 'error' && (
          <div className="flex items-center gap-2 text-sm text-red-400">
            <AlertCircle className="w-4 h-4" />
            {errorMessage}
          </div>
        )}

        {/* Help Link */}
        <div className="flex items-center gap-2 text-sm text-charcoal-400">
          <Link className="w-4 h-4" />
          <a
            href="https://business.udemy.com/api-documentation/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-electric-blue"
          >
            Need help finding your auth token?
          </a>
        </div>
      </div>
    </Modal>
  );
}