// @ts-nocheck
import React from 'react';
import { Key, Loader2 } from 'lucide-react';
import { useState } from 'react';
import { changePassword } from '@/lib/auth';
import { cn } from '@/lib/utils';
import { Modal } from '../ui/Modal';

export const SecuritySettings = () => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetForm = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setError('');
    setSuccess('');
  };

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (currentPassword.length < 6) {
      setError('Current password is required');
      return;
    }

    if (newPassword.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);
    try {
      await changePassword(currentPassword, newPassword);
      setSuccess('Password updated successfully');
      resetForm();
      setShowChangePassword(false);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-white">Security</h3>
      
      <div className="space-y-4">
        <div className="bg-charcoal-800 p-4 rounded-lg">
          <div className="flex items-center gap-3">
            <Key className="w-5 h-5 text-electric-blue" />
            <div>
              <p className="text-sm font-medium text-white">Change Password</p>
              <p className="text-sm text-gray-400">Update your password</p>
            </div>
            <button
              onClick={() => setShowChangePassword(!showChangePassword)}
              className={cn(
                "ml-auto px-3 py-1.5 rounded-lg text-sm",
                "bg-electric-blue/10 text-electric-blue",
                "border border-electric-blue/20 hover:border-electric-blue/40",
                "transition-colors duration-200"
              )}
            >
              Change
            </button>
          </div>
        </div>
      </div>
      
      <Modal
        isOpen={showChangePassword}
        onClose={() => {
          setShowChangePassword(false);
          resetForm();
        }}
        title="Change Password"
      >
        <form onSubmit={handleChangePassword} className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              Current Password
            </label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className={cn(
                "w-full px-3 py-2 rounded-lg",
                "bg-charcoal-700 border border-charcoal-600",
                "text-white placeholder-charcoal-400",
                "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
              )}
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              New Password
            </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={cn(
                "w-full px-3 py-2 rounded-lg",
                "bg-charcoal-700 border border-charcoal-600",
                "text-white placeholder-charcoal-400",
                "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
              )}
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              Confirm New Password
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={cn(
                "w-full px-3 py-2 rounded-lg",
                "bg-charcoal-700 border border-charcoal-600",
                "text-white placeholder-charcoal-400",
                "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
              )}
            />
          </div>

          {error && (
            <div className="text-red-400 text-sm p-3 bg-red-400/10 border border-red-400/20 rounded-lg">
              {error}
            </div>
          )}

          {success && (
            <div className="text-green-400 text-sm p-3 bg-green-400/10 border border-green-400/20 rounded-lg">
              {success}
            </div>
          )}

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={() => {
                setShowChangePassword(false);
                resetForm();
              }}
              className="px-4 py-2 text-sm text-charcoal-400 hover:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className={cn(
                "px-4 py-2 rounded-lg text-sm",
                "bg-electric-blue text-charcoal-900",
                "hover:bg-electric-blue/90",
                "transition-colors duration-200",
                "disabled:opacity-50 disabled:cursor-not-allowed",
                "flex items-center gap-2"
              )}
            >
              {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
              {isLoading ? 'Updating...' : 'Update Password'}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};