// @ts-nocheck
import React, { useState } from 'react';
import { Settings, User, CreditCard, Bell, Shield, Search, Link2, Cpu, Wrench, Archive } from 'lucide-react';
import { ProfileSettings } from './ProfileSettings';
import { SubscriptionSettings } from './SubscriptionSettings';
import { NotificationSettings } from './NotificationSettings';
import { SecuritySettings } from './SecuritySettings';
import { SearchSettings } from './SearchSettings';
import { IntegrationsSettings } from './IntegrationsSettings';
import { ArchivedChatsSettings } from './ArchivedChatsSettings';
import { AISettings } from './AISettings';
import { ApiSettings } from './ApiSettings';
import { ThemeToggle } from '../layout/ThemeToggle';
import { cn } from '@/lib/utils';
import { useSettingsStore } from '@/lib/stores/settingsStore';

type SettingsTab = 'profile' | 'subscription' | 'notifications' | 'security' | 'search' | 'integrations' | 'ai' | 'api' | 'archived';

export function SettingsPage() {
  const { activeTab, showUdemyModal } = useSettingsStore();
  const { setShowSettings } = useSettingsStore();

  const tabs = [
    { id: 'profile' as const, icon: User, label: 'Profile' },
    { id: 'subscription' as const, icon: CreditCard, label: 'Subscription' },
    { id: 'notifications' as const, icon: Bell, label: 'Notifications' },
    { id: 'security' as const, icon: Shield, label: 'Security' },
    { id: 'search' as const, icon: Search, label: 'Search' },
    { id: 'integrations' as const, icon: Link2, label: 'Integrations' },
    { id: 'ai' as const, icon: Cpu, label: 'AI Model' },
    { id: 'archived' as const, icon: Archive, label: 'Archived Chats' },
    { id: 'api' as const, icon: Wrench, label: 'API Settings' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'profile':
        return <ProfileSettings />;
      case 'subscription':
        return <SubscriptionSettings />;
      case 'notifications':
        return <NotificationSettings />;
      case 'security':
        return <SecuritySettings />;
      case 'search':
        return <SearchSettings />;
      case 'integrations':
        return <IntegrationsSettings />;
      case 'ai':
        return <AISettings />;
      case 'archived':
        return <ArchivedChatsSettings />;
      case 'api':
        return <ApiSettings />;
    }
  };

  return (
    <div className="min-h-screen bg-charcoal-950 text-white p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center mb-12 w-fit">
          <div className="flex items-center gap-4 mr-4">
            <Settings className="w-10 h-10 text-electric-blue" />
            <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-electric-blue bg-clip-text text-transparent">
              Settings
            </h1>
          </div>
          <ThemeToggle />
        </div>

        <div className="flex flex-col md:flex-row gap-8">
          {/* Sidebar */}
          <div className="w-full md:w-64 space-y-2">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setShowSettings(true, tab.id)}
                className={cn(
                  "w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-colors",
                  activeTab === tab.id
                    ? "bg-electric-blue text-charcoal-900 font-medium"
                    : "text-charcoal-400 hover:text-white hover:bg-charcoal-800"
                )}
              >
                <tab.icon className="w-5 h-5" />
                {tab.label}
              </button>
            ))}
          </div>

          {/* Main Content */}
          <div className="flex-1">
            <div className="bg-charcoal-900/50 rounded-xl p-8 shadow-lg border border-charcoal-800/50">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}