// @ts-nocheck
import React from 'react';
import { Search, X } from 'lucide-react';
import type { ViewMode, SearchResult } from './types';
import { cn } from '@/lib/utils';
import { useSearchStateStore } from '@/lib/stores/searchStateStore';
import { searchContent } from '@/lib/services/search';
import { CourseCard } from './CourseCard'; 

interface TableRowProps {
  index: number;
  viewMode: ViewMode;
  selected: boolean;
  isDragging?: boolean;
  onSelect: (selected: boolean) => void;
  onDragStart: () => void;
  onDragMove: () => void;
}

export function TableRow({ 
  index, 
  viewMode, 
  selected, 
  isDragging,
  onSelect,
  onDragStart,
  onDragMove 
}: TableRowProps) {
  const { searches, setSearch } = useSearchStateStore();
  const { term: searchTerm = '', results = [], isLoading = false, error = null } = searches.get(index) || {};
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isPasting, setIsPasting] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  // Each search request will have a requestId to ensure results map back to the correct request/row
  const requestIdRef = React.useRef<number>(0);

  const handleSearch = React.useCallback(async (term: string, rowIndex: number) => {
    // If term is empty, just clear results and return
    if (!term.trim()) {
      setSearch(rowIndex, { term, results: [], isLoading: false, error: null });
      return;
    }

    const currentRequestId = ++requestIdRef.current;
    setSearch(rowIndex, { term, isLoading: true, error: null });

    try {
      const searchResults = await searchContent(term, 'course');
      // Only set state if this is the latest request for that row
      if (currentRequestId === requestIdRef.current) {
        setSearch(rowIndex, { results: searchResults, isLoading: false });
      }
    } catch (err) {
      console.error('Search error:', err);
      if (currentRequestId === requestIdRef.current) {
        setSearch(rowIndex, { error: 'Failed to fetch results', isLoading: false });
      }
    }
  }, [setSearch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handleSearch(value, index);
  };

  const processPasteRows = React.useCallback(async (rows: string[], currentIndex: number) => {
    const inputs = document.querySelectorAll<HTMLInputElement>('input[type="text"]');
    
    // Collect all row indices and terms
    const pasteOperations: { rowIndex: number; term: string }[] = [];
    rows.forEach((rowValue, i) => {
      const input = inputs[currentIndex + i];
      if (input) {
        const rowIndex = currentIndex + i;
        pasteOperations.push({ rowIndex, term: rowValue });
      }
    });

    // Mark all rows as loading and trigger searches concurrently
    pasteOperations.forEach(({ rowIndex, term }) => {
      setSearch(rowIndex, { term, isLoading: true, error: null });
    });

    // Perform all searches in parallel
    const resultsPromises = pasteOperations.map(({rowIndex, term}) =>
      searchContent(term, 'course')
        .then((res) => ({ rowIndex, term, results: res, error: null }))
        .catch((err) => {
          console.error('Search error:', err);
          return { rowIndex, term, results: [], error: 'Failed to fetch results' };
        })
    );

    const allResults = await Promise.all(resultsPromises);

    // Update state for each row once all searches are done
    // Since we run them in bulk, just trust that we are updating the correct row
    allResults.forEach(({rowIndex, term, results, error}) => {
      setSearch(rowIndex, { term, results, isLoading: false, error });
    });

    // Focus next empty field
    const nextEmptyIndex = currentIndex + rows.length;
    if (nextEmptyIndex < inputs.length) {
      inputs[nextEmptyIndex].focus();
    }
    setIsPasting(false);
  }, [setSearch]);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsPasting(true);

    const pasteData = e.clipboardData.getData('text');
    const rows = pasteData
      .split('\n')
      .map(r => r.trim())
      .filter(r => r.length > 0);

    const inputs = document.querySelectorAll<HTMLInputElement>('input[type="text"]');
    const currentIndex = Array.from(inputs).indexOf(e.currentTarget);
    processPasteRows(rows, currentIndex);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputs = document.querySelectorAll<HTMLInputElement>('input[type="text"]');
    const currentIndex = Array.from(inputs).indexOf(e.currentTarget);
    const value = e.currentTarget.value.trim();
    let nextIndex;

    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        if (value) {
          handleSearch(value, currentIndex);
          nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
            inputs[nextIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        nextIndex = currentIndex - 1;
        if (nextIndex >= 0) {
          inputs[nextIndex].focus();
          inputs[nextIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        nextIndex = currentIndex + 1;
        if (nextIndex < inputs.length) {
          inputs[nextIndex].focus();
          inputs[nextIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
        break;
      case 'Tab':
        if (value) {
          handleSearch(value, currentIndex);
        }
        break;
    }
  };

  const PasteLoadingOverlay = () => (
    <div className="relative">
      <div className={cn(
        "absolute inset-0 flex items-center justify-center",
        "bg-charcoal-800/90 rounded-lg border border-charcoal-700/30",
        "text-electric-blue backdrop-blur-sm z-10"
      )}>
        <div className="flex items-center gap-2">
          <svg className="animate-spin w-5 h-5" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          <span>Processing paste...</span>
        </div>
      </div>
    </div>
  );

  const LoadingSpinner = () => (
    <div className="flex items-center justify-center h-24 bg-charcoal-800/30 rounded-lg border border-charcoal-700/20">
      <div className="animate-spin text-electric-blue">
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      </div>
    </div>
  );

  const ErrorDisplay = ({ message }: { message: string }) => (
    <div className="flex items-center justify-center h-24 bg-charcoal-800/30 rounded-lg border border-charcoal-700/20">
      <div className="flex flex-col items-center gap-2 text-red-400">
        <X className="w-5 h-5" />
        <div className="text-sm">{message}</div>
      </div>
    </div>
  );

  const NoResults = ({ term }: { term: string }) => (
    <div className="flex items-center justify-center h-24 bg-charcoal-800/20 rounded-lg border border-charcoal-700/20">
      <div className="flex items-center gap-2 text-charcoal-500">
        <Search className="w-4 h-4 text-charcoal-600" />
        <span className="text-xs">
          {term ? 'No results found' : 'Start typing to discover content'}
        </span>
      </div>
    </div>
  );

  const ResultsDisplayPretty = () => (
    <div className="space-y-4">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
        {results.length > 0 && (
          <div className="lg:col-span-2">
            <CourseCard course={results[0]} className="bg-charcoal-700/30" />
          </div>
        )}
        {results.slice(1).map((course, i) => (
          <CourseCard key={i} course={course} compact />
        ))}
      </div>
    </div>
  );

  const ResultsDisplayCompact = () => (
    <div className="space-y-2">
      {results.map((course, i) => (
        <CourseCard 
          key={i} 
          course={course} 
          compact 
          minimal 
          className="py-2 px-3"
        />
      ))}
    </div>
  );

  if (isPasting) return <PasteLoadingOverlay />;

  const baseInputProps = {
    type: "text",
    ref: inputRef,
    value: searchTerm,
    onChange: handleInputChange,
    onPaste: handlePaste,
    onKeyDown: handleKeyDown,
    placeholder: "Search...",
    className: cn(
      "w-full px-4 py-2 rounded-lg",
      "bg-charcoal-800/50 border border-charcoal-700/30",
      "text-white placeholder-charcoal-400",
      "focus:outline-none focus:ring-1 focus:ring-electric-blue/30",
      "hover:border-charcoal-600/50 transition-colors duration-200"
    )
  };

  if (viewMode === 'pretty') {
    return (
      <div 
        className={cn(
          "p-4 transition-all duration-200 cursor-pointer",
          "bg-charcoal-800/30 hover:bg-charcoal-800/50",
          "border border-charcoal-700/30 hover:border-electric-blue/30",
          "rounded-lg relative",
          selected && "bg-electric-blue/5",
          isDragging && "border-electric-blue"
        )}
        onClick={() => onSelect(!selected)}
        onMouseDown={() => onDragStart()}
        onMouseEnter={() => onDragMove()}
      >
        <div className="absolute left-4 top-1/2 -translate-y-1/2 text-xs text-charcoal-500">
          {String(index + 1).padStart(2, '0')}
        </div>
        <div className="flex gap-4 w-full">
          <div className="w-1/5 min-w-[180px] ml-8">
            <input
              {...baseInputProps}
              onFocus={() => setIsActive(true)}
              onBlur={() => setIsActive(false)}
              className={cn(baseInputProps.className, isActive && "border-electric-blue")}
            />
          </div>
          <div className="flex-1 space-y-4">
            {isLoading ? (
              <LoadingSpinner />
            ) : error ? (
              <ErrorDisplay message={error} />
            ) : results.length > 0 ? (
              <ResultsDisplayPretty />
            ) : (
              <NoResults term={searchTerm} />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div 
      className={cn(
        "flex items-start gap-2 p-2 transition-all duration-200 cursor-pointer",
        "bg-charcoal-800/30 hover:bg-charcoal-800/50",
        "border border-charcoal-700/30 hover:border-electric-blue/30",
        "rounded-lg",
        selected && "bg-electric-blue/5 hover:bg-electric-blue/10",
        isDragging && "border-electric-blue"
      )}
      onClick={() => onSelect(!selected)}
      onMouseDown={() => onDragStart()}
      onMouseEnter={() => onDragMove()}
    >
      <div className="flex-shrink-0 w-5 text-[10px] text-charcoal-600/50 pt-2.5 text-right pr-1.5">
        {String(index + 1).padStart(2, '0')}
      </div>
      <div className="w-1/5 min-w-[180px]">
        <input {...baseInputProps} />
      </div>
      <div className="flex-1 space-y-2">
        {isLoading ? (
          <div className="flex items-center justify-center text-electric-blue">
            <svg className="animate-spin w-5 h-5" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
          </div>
        ) : error ? (
          <div className="text-red-400 text-sm">{error}</div>
        ) : results.length > 0 ? (
          <ResultsDisplayCompact />
        ) : (
          <NoResults term={searchTerm} />
        )}
      </div>
    </div>
  );
}
