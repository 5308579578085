// @ts-nocheck
import React, { useState } from 'react';
import { Mail, Loader2, AlertCircle, CheckCircle2 } from 'lucide-react';
import { sendPasswordResetEmail } from '@/lib/auth';
import { isValidEmail, cn } from '@/lib/utils';

interface ResetPasswordFormProps {
  onSwitchToLogin: () => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSwitchToLogin }) => {
  const [email, setEmail] = useState(() => {
    const savedEmail = localStorage.getItem('lastEmail') || '';
    return savedEmail;
  });
  const [isEmailValid, setIsEmailValid] = useState(() => {
    const savedEmail = localStorage.getItem('lastEmail') || '';
    return isValidEmail(savedEmail);
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(isValidEmail(newEmail));
    localStorage.setItem('lastEmail', newEmail);
    setError('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    
    if (!email.trim()) {
      setError('Please enter your email address');
      return;
    }
    
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    try {
      await sendPasswordResetEmail(email);
      setSuccessMessage('Password reset email sent! Please check your inbox.');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-form-content">
      <h2 className="text-3xl font-bold text-white mb-6 text-center">
        Reset Password
      </h2>
      
      <p className="text-charcoal-300 text-center mb-6">
        Enter your email address and we'll send you instructions to reset your password.
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-charcoal-400 h-5 w-5" />
          <input
            aria-label="Email address"
            type="email"
            autoFocus={!isEmailValid}
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
            className={cn(
              "w-full pl-10 pr-4 py-2 bg-charcoal-700 border border-charcoal-600 rounded-lg",
              "focus:ring-2 focus:ring-electric-blue focus:border-transparent",
              "text-white placeholder-charcoal-400 transition-all duration-300"
            )}
          />
        </div>

        {error && (
          <div className="flex items-center gap-2 text-red-400 text-sm">
            <AlertCircle className="w-4 h-4" />
            <p className="flex-1 font-medium">{error}</p>
          </div>
        )}

        {successMessage && (
          <div className="flex items-center gap-2 text-green-400 text-sm p-3 bg-green-400/10 border border-green-400/20 rounded-lg">
            <CheckCircle2 className="w-4 h-4 flex-shrink-0" />
            {successMessage}
          </div>
        )}

        <button
          type="submit"
          disabled={isLoading || !isEmailValid}
          className={cn(
            "w-full py-2.5 px-4 rounded-lg font-medium transition-colors",
            "disabled:opacity-50 disabled:cursor-not-allowed",
            "flex items-center justify-center",
            "bg-electric-blue hover:bg-electric-blue/90 text-charcoal-900",
            !isEmailValid && "opacity-0"
          )}
        >
          {isLoading ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            'Reset Password'
          )}
        </button>
      </form>

      <div className="mt-6 text-center">
        <button
          onClick={onSwitchToLogin}
          className="text-electric-blue hover:underline text-sm"
        >
          Back to login
        </button>
      </div>
    </div>
  );
};