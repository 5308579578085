// @ts-nocheck
import React, { useState } from 'react';
import { Cpu, Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';

interface ApiResponse {
  skillTree?: any;
  error?: string;
}

export function ApiSettings() {
  const [response, setResponse] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const testApi = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await fetch('/api/skill-tree', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: 'Generate a skill tree for learning React development',
          model: 'gpt-4-0125-preview'
        }),
      });
      const data = await res.json();
      setResponse(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to test API');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <Cpu className="w-6 h-6 text-electric-blue" />
        <h3 className="text-lg font-medium text-white">API Settings</h3>
      </div>

      <div className="space-y-4">
        <div className={cn(
          "p-6 rounded-lg",
          "bg-charcoal-800/50 border border-charcoal-700/50",
          "hover:border-electric-blue/20",
          "transition-all duration-200"
        )}>
          <h4 className="text-sm font-medium text-white mb-4">API Connection Test</h4>
          
          <button
            onClick={testApi}
            disabled={loading}
            className={cn(
              "flex items-center gap-2 px-4 py-2 rounded-lg",
              "bg-electric-blue hover:bg-electric-blue/90",
              "text-charcoal-900 font-medium",
              "transition-colors duration-200",
              "disabled:opacity-50 disabled:cursor-not-allowed"
            )}
          >
            {loading ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                Testing...
              </>
            ) : (
              'Test Skill Tree API'
            )}
          </button>

          {error && (
            <div className="mt-4 p-3 bg-red-500/10 border border-red-500 rounded-lg text-red-400">
              {error}
            </div>
          )}

          {response && (
            <div className="mt-4 p-3 bg-green-500/10 border border-green-500 rounded-lg">
              <p className="text-green-500 mb-2">API call successful!</p>
              <pre className="text-sm text-white overflow-auto max-h-[300px] p-2 bg-charcoal-900/50 rounded-lg">
                {JSON.stringify(response, null, 2)}
              </pre>
            </div>
          )}
        </div>

        <div className={cn(
          "p-6 rounded-lg",
          "bg-charcoal-800/50 border border-charcoal-700/50",
          "hover:border-electric-blue/20",
          "transition-all duration-200"
        )}>
          <h4 className="text-sm font-medium text-white mb-2">API Configuration</h4>
          <p className="text-charcoal-400 text-sm">
            The API is configured to use the following endpoints:
          </p>
          <ul className="mt-2 space-y-1 text-sm text-charcoal-300">
            <li>• Skill Tree Generation: <code className="text-electric-blue">/api/skill-tree</code></li>
            <li>• Chat Completion: <code className="text-electric-blue">/api/chat</code></li>
            <li>• Learning Path: <code className="text-electric-blue">/api/learning-path</code></li>
          </ul>
        </div>
      </div>
    </div>
  );
}