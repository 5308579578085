// @ts-nocheck
import { ProcessedFile, FileProcessor } from './types';
import { processPdfFile } from './processors/pdfProcessor';
import { processWordFile } from './processors/wordProcessor';
import { processSpreadsheetFile } from './processors/spreadsheetProcessor';
import { processTextFile } from './processors/textProcessor';

const processors: Record<string, FileProcessor> = {
  'application/pdf': processPdfFile,
  'application/msword': processWordFile,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': processWordFile,
  'application/vnd.ms-excel': processSpreadsheetFile,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': processSpreadsheetFile,
  'text/csv': processSpreadsheetFile,
  'text/plain': processTextFile,
};

export async function processFile(file: File): Promise<ProcessedFile> {
  const processor = processors[file.type];
  if (!processor) {
    throw new Error(`Unsupported file type: ${file.type}`);
  }
  return processor(file);
}

export * from './types';