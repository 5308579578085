// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// Initialize Firebase before rendering
import './lib/firebase';

// Add error boundary for development
if (import.meta.env.DEV) {
  const errorHandler = (error: Error) => {
    console.error('React Error:', error);
  };
  
  window.addEventListener('error', errorHandler);
  window.addEventListener('unhandledrejection', (event) => errorHandler(event.reason));
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);